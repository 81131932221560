import { DialogIDs, Dialog } from './Dialog.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';

const {
  buttons: btn,
  inputFields: field,
  components,
} = utilsData.elementsDataTestIDs;

export class SignInDialogIDs extends DialogIDs {
  googleSocialLoginBtnID: string;
  appleSocialLoginBtnID: string;
  emailInputID: string;
  passwordInputID: string;
  forgotPasswordBtnID: string;
  loginSubmitBtnID: string;
  openAccountBtnID: string;

  constructor() {
    super(components.dialogs.signIn);

    this.appleSocialLoginBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.appleSocialLogin,
    );
    this.googleSocialLoginBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.googleSocialLogin,
    );
    this.forgotPasswordBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.forgotPassword,
    );

    this.emailInputID = TestIDFormat.inputField(this.fullPrefix, field.email);
    this.passwordInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.password,
    );
    this.loginSubmitBtnID = TestIDFormat.button(this.fullPrefix, btn.submit);
    this.openAccountBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.openAccount,
    );
  }
}

export class SignInDialog extends Dialog<SignInDialogIDs> {
  constructor() {
    super(SignInDialogIDs);
  }

  get appleSocialLoginBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.appleSocialLoginBtnID, timeout);
    };
  }

  get googleSocialLoginBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.googleSocialLoginBtnID, timeout);
    };
  }

  get forgotPasswordBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.forgotPasswordBtnID, timeout);
    };
  }

  get emailInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.emailInputID, timeout);
    };
  }

  get passwordInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.passwordInputID, timeout);
    };
  }

  get loginSubmitBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.loginSubmitBtnID, timeout);
    };
  }

  get openAccountBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.openAccountBtnID, timeout);
    };
  }
}

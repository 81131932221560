import { AddressInput } from 'teddly-sdk';

export enum PaymentGatewayType {
  STRIPE = 'STRIPE',
  AUTHORIZE = 'AUTHORIZE',
  CARDKNOX = 'CARDKNOX',
}

export interface PaymentSourceInput {
  isDefault?: boolean | null;
  billingAddress: AddressInput | null;
  authenticationData: string | null;
}

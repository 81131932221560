import { useNetworkState } from '@hooks/useNetworkState';
import { NewPaymentDetails } from '@interfaces';
import { authenticateCardOnAuthorizeNet } from '@utils/paymentGateways';
import AuthorizeNetPaymentDetailsForm from '../../forms/PaymentDetails/AuthorizeNetPaymentDetailsForm';
import { IAddress } from 'teddly-sdk/lib/api/Checkout/types';
import { useCheckoutPaymentContext } from '@context/CheckoutPaymentContext';
import { CountryCode } from 'teddly-sdk';
import { createPaymentSource } from '@app/payment';
import { PaymentGatewayType } from '@app/payment/types';
import { useLoadAcceptJS } from '../../hooks';
import { AuthorizeNetAuthData } from '../../context/checkout-flow';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { AddPaymentMethodDialogIDs } from '@cypress/components/dialogs/AddPaymentMethod.cy';

type Props = {
  authData: AuthorizeNetAuthData;
  onAddPaymentCard: () => void;
  onBillingAddress: (address: IAddress) => void;
  testIds: AddPaymentMethodDialogIDs
};

export default function AuthorizeNetAddPaymentCard({
  authData,
  onAddPaymentCard,
  onBillingAddress,
  testIds
}: Props) {
  useLoadAcceptJS();
  const { state, setLoading, setError, setCompleted } =
    useNetworkState();
  const {
    closeCurrentDialog,
    openPickPaymentForm,
    isOpenAddPaymentForm,
  } = useCheckoutPaymentContext();

  const { addSnackbar, closeSnackbar } = usePageLayoutContext();

  const loading = state.status === 'loading';

  const handleSubmit = ({
    cardInputData,
    billingAddress,
  }: {
    cardInputData: NewPaymentDetails;
    billingAddress: IAddress;
  }) => {
    setLoading();
    onBillingAddress({
      ...billingAddress,
    });
    authenticateCardOnAuthorizeNet(cardInputData, authData)
      .then((token) => {
        createPaymentSource(PaymentGatewayType.AUTHORIZE, {
          authenticationData: JSON.stringify(token),
          billingAddress: {
            city: billingAddress?.city,
            companyName: billingAddress?.companyName,
            country: billingAddress.country.code as CountryCode,
            countryArea: billingAddress?.countryArea,
            firstName: billingAddress?.firstName,
            id: billingAddress?.id,
            lastName: billingAddress?.lastName,
            note: billingAddress?.note,
            phone: billingAddress?.phone,
            postalCode: billingAddress?.postalCode,
            streetAddress1: billingAddress?.streetAddress1,
            streetAddress2: billingAddress?.streetAddress2,
            tag: billingAddress?.tag?.id,
          },
        })
          .then(({ success, errors }) => {
            if (success) {
              setCompleted({ cardInputData, token });
              onAddPaymentCard();
              addSnackbar({
                id: 'success',
                onClose: () => closeSnackbar('success'),
                message: 'Payment card is added successfully!',
                variant: SnackbarVariant.SUCCESS,
              });
            }
            if (errors?.length > 0) {
              throw errors[0];
            }
          })
          .catch((e) => setError(e));
      })
      .catch((e) => {
        console.error(e);
        let error = 'Something went wrong!';
        if (typeof e === 'object' && e.length) {
          error = e[0].text;
        }
        setError(error);
        addSnackbar({
          id: 'error',
          onClose: () => closeSnackbar('error'),
          message: state.error || 'failed',
          variant: SnackbarVariant.ERROR,
        });
      });
  };

  const handleClose = () => {
    closeCurrentDialog();
    openPickPaymentForm();
  };

  return (
    <>
      <AuthorizeNetPaymentDetailsForm
        testIds={testIds}
        isOpen={isOpenAddPaymentForm}
        onClose={handleClose}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
}

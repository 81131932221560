import { useEffect, useState } from 'react';

import { useAuth, useCategoryListQuery } from 'teddly-sdk';

import { usePageLayoutContext } from '@context/PageLayoutContext';

import { SideNavBar } from '@teddly/teddly-ui-components';
import { SideNavBarIDs } from '@cypress/components/navigation/SideNavbar.cy';

import GroceryIcon from '../../../../public/assets/SideBarIcons/shopping_cart.svg';
import HouseholdIcon from '../../../../public/assets/SideBarIcons/cleaning.svg';
import PharmacyIcon from '../../../../public/assets/SideBarIcons/local_pharmacy.svg';
import KidsAndToysIcon from '../../../../public/assets/SideBarIcons/toys.svg';
import ClothesAndShoesIcon from '../../../../public/assets/SideBarIcons/checkroom.svg';
import JudaicaIcon from '../../../../public/assets/SideBarIcons/auto_stories.svg';
import BrowseStoresIcon from '../../../../public/assets/SideBarIcons/storefront.svg';
import LocalProsIcon from '../../../../public/assets/SideBarIcons/event_available.svg';
import RestaurantsIcon from '../../../../public/assets/SideBarIcons/ramen_dining.svg';
import MyOrdersIcon from '../../../../public/assets/SideBarIcons/local_mall.svg';
import ShoppingListIcon from '../../../../public/assets/SideBarIcons/summarize.svg';
import AccountSettingsIcon from '../../../../public/assets/SideBarIcons/settings_heart.svg';
import useWindowDimensions from '@hooks/useWindowDimension';
import { useRoutesContext } from '@context/RoutesContext';
import { useChannelContext } from '@context/ChannelContext';

const rootCategories = [
  {
    categories: [],
    id: 'Q2F0ZWdvcnk6NjE4',
    imgSrc:
      'https://nyc3.digitaloceanspaces.com/teddly-dev-space/category-backgrounds/Grocery.png',
    name: 'Grocery',
    slug: 'grocery',
    vendors_count: 15,
  },
  {
    categories: [],
    id: 'Q2F0ZWdvcnk6ODEw',
    imgSrc:
      'https://nyc3.digitaloceanspaces.com/teddly-dev-space/category-backgrounds/Household__Electronics.png',
    name: 'Household',
    slug: 'household',
    vendors_count: 13,
  },
  {
    categories: [],
    id: 'Q2F0ZWdvcnk6OTI1',
    imgSrc:
      'https://nyc3.digitaloceanspaces.com/teddly-dev-space/category-backgrounds/Judaica.png',
    name: 'Judaica',
    slug: 'judaica',
    vendors_count: 10,
  },
  {
    categories: [],
    id: 'Q2F0ZWdvcnk6OTk1',
    imgSrc:
      'https://nyc3.digitaloceanspaces.com/teddly-dev-space/category-backgrounds/Pharmacy_Health__Beauty.png',
    name: 'Pharmacy',
    slug: 'pharmacy',
    vendors_count: 9,
  },
  {
    categories: [],
    id: 'Q2F0ZWdvcnk6MTA0Ng==',
    imgSrc:
      'https://nyc3.digitaloceanspaces.com/teddly-dev-space/category-backgrounds/Clothes__Toys.png',
    name: 'Toys & Clothes',
    slug: 'clothes-and-toys',
    vendors_count: 11,
  },
];

// const IconsBySlug = {
//   home: <HomeIcon />,
//   grocery: <GroceryIcon />,
//   household: <HouseholdIcon />,
//   judaica: <JudaicaIcon />,
//   'clothes-and-toys': <ClothesAndToysIcon />,
//   pharmacy: <PharmacyIcon />,
//   'book-local-pros': <BookLocalProsIcon />,
//   stores: <ShoppingBag width={'32px'} height={'32px'} strokeWidth={1.5} />,
// };

function SideBar() {
  const { router, navigateTo, pages, isCurrentPage, routes } =
    useRoutesContext();
  const testIds = new SideNavBarIDs();
  const { isLargeTablet } = useWindowDimensions();
  const { isChannelSelected, selectedChannel } = useChannelContext();
  const { data, refetch } = useCategoryListQuery({
    variables: { first: 100, includeAllChannels: false, level: 0 },
    skip: !isChannelSelected,
    fetchPolicy: 'cache-first',
  });

  const sideBarItemsMap: Record<
    string,
    { icon: JSX.Element; testId?: string }
  > = {
    grocery: { icon: <GroceryIcon />, testId: testIds.groceryBtnID },
    household: { icon: <HouseholdIcon />, testId: testIds.householdBtnID },
    // home: <HomeIcon />,
    pharmacy: { icon: <PharmacyIcon />, testId: testIds.pharmacyBtnID },
    'kids-and-toys': { icon: <KidsAndToysIcon /> },
    'clothes-and-shoes': { icon: <ClothesAndShoesIcon /> },
    judaica: { icon: <JudaicaIcon />, testId: testIds.judaicaBtnID },
    'browse-stores': {
      icon: <BrowseStoresIcon />,
      testId: testIds.browseStoresBtnID,
    },
    'local-prod': { icon: <LocalProsIcon />, testId: testIds.localProsBtnID },
    restaurants: {
      icon: <RestaurantsIcon />,
      testId: testIds.restaurantsBtnID,
    },
    'order-history': { icon: <MyOrdersIcon />, testId: testIds.myOrdersBtnID },
    'shopping-lists': {
      icon: <ShoppingListIcon />,
      testId: testIds.shoppingListsBtnID,
    },
    'my-account': {
      icon: <AccountSettingsIcon />,
      testId: testIds.accountSettingsBtnID,
    },
  };

  useEffect(() => {
    refetch();
  }, [selectedChannel]);
  const rootCategoriesSlugs = data?.categories?.edges?.map(
    (node) => node.node?.slug,
  );

  const { authenticated } = useAuth();
  const {
    isSidebarNavOpen: isSideBarOpen,
    closeSidebarNav,
    setLoading,
  } = usePageLayoutContext();

  useEffect(() => {}, [isLargeTablet]);

  const optionsMenu: {
    name: string;
    slug: string;
    testId: string;
  }[] = [
    {
      name: 'My Orders',
      slug: pages.ORDER_HISTORY,
      testId: testIds.myOrdersBtnID,
    },
    {
      name: 'Shopping List',
      slug: pages.SHOPPING_LISTS,
      testId: testIds.shoppingListsBtnID,
    },
    {
      name: 'Account Settings',
      slug: pages.MY_ACCOUNT,
      testId: testIds.accountSettingsBtnID,
    },
  ];

  type sideBarItem = {
    name: string;
    onClick?: () => void;
    selected?: boolean;
    icon: JSX.Element;
    isSoon?: boolean;
    testId: string;
  };

  const categoriesMenuExtension: sideBarItem[] = [
    {
      name: 'Browse stores',
      icon: sideBarItemsMap['browse-stores']?.icon,
      isSoon: false,
      onClick: () =>
        navigateTo({
          route: pages.STORES_LIST,
          options: { scroll: true },
          showLoader: true,
        }),
      selected: isCurrentPage(pages.STORES_LIST),
      testId: sideBarItemsMap['browse-stores']?.testId,
    },
    {
      name: 'Local Pros',
      icon: sideBarItemsMap['local-prod']?.icon,
      isSoon: true,
      testId: sideBarItemsMap['local-prod']?.testId,
    },
    {
      name: 'Restaurants',
      icon: sideBarItemsMap['restaurants']?.icon,
      isSoon: true,
      testId: sideBarItemsMap['restaurants']?.testId,
    },
  ];

  let categories: sideBarItem[] = rootCategories
    .filter(
      (category) =>
        rootCategoriesSlugs?.find((slug) => slug === category?.slug) &&
        Object.keys(sideBarItemsMap).find(
          (iconName) => iconName === category.slug,
        ),
    )
    .map((category) => {
      const sidebarItem = sideBarItemsMap[category.slug];
      const link = `/${category?.slug}`;
      return {
        name: category.name,
        isSoon: false,
        icon: sidebarItem.icon,
        onClick: () => {
          setLoading(true);
          navigateTo({
            route: link,
            options: { scroll: true },
          });
        },
        selected: router?.query?.categorySlug?.includes(category.slug),
        testId: sidebarItem.testId,
      };
    });
  categories = categories.concat(categoriesMenuExtension);
  // const optionsMenus = optionsMenu;
  const options: sideBarItem[] = optionsMenu.map((menu) => {
    const slugAsRouts = routes[menu?.slug];
    return {
      name: menu.name,
      icon: sideBarItemsMap[slugAsRouts.substring(1, slugAsRouts.length)]?.icon,
      onClick: () =>
        navigateTo({
          route: menu.slug,
          options: { scroll: true },
          showLoader: true,
        }),
      isSoon: false,
      selected: router?.pathname.includes(slugAsRouts),
      testId: menu.testId,
    };
  });
  return (
    <>
      {isChannelSelected && (
        <SideNavBar
          dataTestId={testIds.categoriesID}
          isOpen={isSideBarOpen}
          onClose={closeSidebarNav}
          onOutsideClick={closeSidebarNav}
          categories={categories}
          categoriesDataTestId={testIds.categoriesID}
          optionsDataTestId={testIds.optionsID}
          optionsTitle={authenticated ? 'my teddly' : 'teddly'}
          options={authenticated ? options : []}
        />
      )}
    </>
  );
}

export default SideBar;

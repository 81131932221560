import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import {
  ICollapsible,
  ICollapsibleIDs,
} from '../../interfaces/ICollapsible.cy';
import TestObjectBase from '../TestObjectBase';

const {
  buttons: btn,
  namedElements: elm,
  components,
} = utilsData.elementsDataTestIDs;

export class CollapsibleIDs implements ICollapsibleIDs {
  titleID: string;
  bodyID: string;
  expandBtnID: string;

  protected fullPrefix: string;

  constructor(prefix: string) {
    this.fullPrefix = `${prefix}-${components.collapsible.title}`;

    this.titleID = TestIDFormat.element(this.fullPrefix, elm.title);
    this.bodyID = TestIDFormat.element(this.fullPrefix, elm.body);
    this.expandBtnID = TestIDFormat.button(this.fullPrefix, btn.expand);
  }
}

export class Collapsible<T extends CollapsibleIDs>
  extends TestObjectBase<T>
  implements ICollapsible
{
  constructor(collapsibleIDs: CollapsibleIDs) {
    super(collapsibleIDs);
  }

  get title() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.titleID, timeout);
    };
  }
  get body() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.bodyID, timeout);
    };
  }
  get expandBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.expandBtnID, timeout);
    };
  }
}

import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import TestObjectBase from '../TestObjectBase';

const { components, namedElements: elm } = utilsData.elementsDataTestIDs;

export class SideNavBarIDs {
  bodyID: string;
  categoriesID: string;
  optionsID: string;
  groceryBtnID: string;
  householdBtnID: string;
  judaicaBtnID: string;
  pharmacyBtnID: string;
  browseStoresBtnID: string;
  localProsBtnID: string;
  restaurantsBtnID: string;
  myOrdersBtnID: string;
  shoppingListsBtnID: string;
  accountSettingsBtnID: string;

  protected fullPrefix: string;

  constructor() {
    this.fullPrefix = components.navigation.sideNavBar;
    this.bodyID = TestIDFormat.element(this.fullPrefix, elm.body);
    this.categoriesID = TestIDFormat.element(
      this.fullPrefix,
      components.pages.categories.title,
    );
    this.optionsID = TestIDFormat.element(
      this.fullPrefix,
      components.pages.options.title,
    );
    this.groceryBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.pages.categories.grocery,
    );
    this.householdBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.pages.categories.household,
    );
    this.judaicaBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.pages.categories.judaica,
    );
    this.pharmacyBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.pages.categories.pharmacy,
    );
    this.browseStoresBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.pages.stores,
    );
    this.localProsBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.pages.pros,
    );
    this.restaurantsBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.pages.restaurants,
    );
    this.myOrdersBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.pages.options.myOrders,
    );
    this.shoppingListsBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.pages.options.shoppingLists,
    );
    this.accountSettingsBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.pages.options.accountSettings,
    );
  }
}

export class SideNavBar extends TestObjectBase<SideNavBarIDs> {
  constructor() {
    super(new SideNavBarIDs());
  }

  get body() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.bodyID, timeout);
    };
  }

  get categories() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.categoriesID, timeout);
    };
  }

  get options() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.optionsID, timeout);
    };
  }

  get groceryBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.groceryBtnID, timeout);
    };
  }

  get householdBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.householdBtnID, timeout);
    };
  }

  get judaicaBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.judaicaBtnID, timeout);
    };
  }

  get pharmacyBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.pharmacyBtnID, timeout);
    };
  }

  get browseStoresBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.browseStoresBtnID, timeout);
    };
  }

  get localProsBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.localProsBtnID, timeout);
    };
  }

  get restaurantsBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.restaurantsBtnID, timeout);
    };
  }

  get myOrdersBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.myOrdersBtnID, timeout);
    };
  }

  get shoppingListsBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.shoppingListsBtnID, timeout);
    };
  }

  get accountSettingsBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.accountSettingsBtnID, timeout);
    };
  }
}

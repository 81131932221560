import { getPrimaryColor } from '@utils';
import SvgTruckIcon from 'public/assets/Truck.svg';

export function TruckIcon(props) {
  return (
    <svg
      strokeWidth="0"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.18934 5.68934C1.47064 5.40804 1.85217 5.25 2.25 5.25H16.5C16.9142 5.25 17.25 5.58579 17.25 6V6.33804L20.5466 6.75077C20.8271 6.75981 21.0999 6.84792 21.3331 7.00551C21.5817 7.17349 21.7738 7.41258 21.8844 7.69148L22.5401 9.33085L23.1899 10.9554C23.2108 11.0043 23.2267 11.0558 23.2368 11.1093C23.2469 11.1619 23.2512 11.2148 23.25 11.267V17.25C23.25 17.6478 23.092 18.0294 22.8107 18.3107C22.5294 18.592 22.1478 18.75 21.75 18.75H20.5305C20.1975 20.0439 19.0229 21 17.625 21C16.2271 21 15.0525 20.0439 14.7195 18.75H9.28049C8.94746 20.0439 7.77288 21 6.375 21C4.97712 21 3.80255 20.0439 3.46951 18.75H2.25C1.85218 18.75 1.47065 18.592 1.18934 18.3107C0.908035 18.0294 0.75 17.6478 0.75 17.25V13.5V6.75C0.75 6.35218 0.908035 5.97064 1.18934 5.68934ZM20.5305 17.25H21.75V12H17.25V13.5V15.0232C17.3728 15.0079 17.498 15 17.625 15C19.0229 15 20.1975 15.9561 20.5305 17.25ZM17.25 10.5H21.3922L21.1474 9.88793L20.4922 8.25001C20.4616 8.24995 20.431 8.24801 20.4006 8.24421L17.25 7.84976V10.5ZM15.75 11.25V7.01184C15.7499 7.00459 15.7499 6.99732 15.75 6.99004V6.75L2.25 6.75V12.75H15.75V11.25ZM2.25 14.25H15.75V15.658C15.2516 16.0575 14.8835 16.6128 14.7195 17.25H9.28049C8.94746 15.9561 7.77288 15 6.375 15C4.97712 15 3.80255 15.9561 3.46951 17.25H2.25V14.25ZM6.375 19.5C5.54657 19.5 4.875 18.8284 4.875 18C4.875 17.1716 5.54657 16.5 6.375 16.5C7.20343 16.5 7.875 17.1716 7.875 18C7.875 18.8284 7.20343 19.5 6.375 19.5ZM16.125 18C16.125 17.1716 16.7966 16.5 17.625 16.5C18.4534 16.5 19.125 17.1716 19.125 18C19.125 18.8284 18.4534 19.5 17.625 19.5C16.7966 19.5 16.125 18.8284 16.125 18Z"
        fill={getPrimaryColor()}
        stroke="#33d1a7"
      />
    </svg>
  );
}

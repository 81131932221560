import { RegionDropdown } from 'react-country-region-selector';
import styles from './style.module.scss';

export default function StateSelectDropdown({
  value,
  onChange,
  disabled = false,
  deleteSelectSign = false,
  title,
  testId,
  ...otherProps
}: {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  deleteSelectSign?:boolean;
    title?: string;
    testId: string
  
}) {
  return (
    <RegionDropdown
      data-testid={testId}
      disabled={disabled}
      classes={
        deleteSelectSign
          ? styles.stateSelectWithOutSelectSign
          : styles.stateSelect
      }
      defaultOptionLabel={title ? title : "State"}
      country="US"
      countryValueType="short"
      value={value}
      onChange={onChange}
      valueType="short"
    />
  );
}

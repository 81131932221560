import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import TestObjectBase from '../TestObjectBase';

const {
  buttons: btn,
  namedElements: elm,
  inputFields: input,
  components,
} = utilsData.elementsDataTestIDs;

export class HeaderIDs {
  logoID: string;
  progressBarID: string;
  arrowBackBtnID: string;
  pickDeliverySlotBtnID: string;
  searchBtnID: string;
  loginBtnID: string;
  accountActionsBtnID: string;
  cartBtnID: string;
  searchInputID: string;
  protected fullPrefix: string;

  constructor() {
    this.fullPrefix = components.navigation.header;

    this.logoID = TestIDFormat.element(this.fullPrefix, elm.logo);
    this.progressBarID = TestIDFormat.element(
      this.fullPrefix,
      components.loaders.progressBar,
    );
    this.arrowBackBtnID = TestIDFormat.button(this.fullPrefix, btn.arrowBack);
    this.pickDeliverySlotBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.pickDeliverySlot,
    );
    this.searchBtnID = TestIDFormat.button(this.fullPrefix, btn.search);
    this.loginBtnID = TestIDFormat.button(this.fullPrefix, btn.logIn);
    this.accountActionsBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.accountActions,
    );
    this.cartBtnID = TestIDFormat.button(this.fullPrefix, btn.cart);
    this.searchInputID = TestIDFormat.inputField(this.fullPrefix, input.search);
  }
}

export class Header extends TestObjectBase<HeaderIDs> {
  constructor() {
    super(new HeaderIDs());
  }

  get logo() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.logoID, timeout);
    };
  }

  get progressBar() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.progressBarID, timeout);
    };
  }

  get arrowBackBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.arrowBackBtnID, timeout);
    };
  }

  get pickDeliveySlotBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.pickDeliverySlotBtnID, timeout);
    };
  }

  get searchBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.searchBtnID, timeout);
    };
  }

  get loginBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.loginBtnID, timeout);
    };
  }

  get accountActionsBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.accountActionsBtnID, timeout);
    };
  }

  get cartBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.cartBtnID, timeout);
    };
  }

  get searchInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.searchInputID, timeout);
    };
  }
}

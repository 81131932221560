export class TestIDFormat {
  static element(prefix: string, element: string) {
    return `${prefix}-${element}`;
  }
  static button(prefix: string, element: string): string {
    return `${prefix}-${element}-button`;
  }

  static inputField(prefix: string, element: string): string {
    return `${prefix}-${element}-input-field`;
  }
}

export class UtilsNew {
  static isButtonDisabled(button) {
    button.should('be.disabled');
  }

  static isButtonEnabled(button) {
    button.should('be.enabled');
  }

  static shouldNotBeEmptyString(str: string | null) {
    return expect(str).to.not.be.empty;
  }

  static shouldBeEmptyString(str: string) {
    return expect(str).to.equal('');
  }

  static shouldBeNull(element) {
    return expect(element).to.null;
  }

  static awaitButtonLoader(element) {
    return element.should('have.attr', 'class').and('not.contain', 'loading');
  }
}

import { useEffect, useRef, useState } from 'react';
import { getProductById } from '@app/products/api';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { useCartContext } from '@context/CartContext';
import { NewProduct, NewVariant, VendorDetails } from '@interfaces';
import { ItemDescription } from '@teddly/teddly-ui-components';
import { ProductsListWidget } from '@app/products/ProductListWidget';
import { useVendorContext } from '@context/VendorContext';
import { useRoutesContext } from '@context/RoutesContext';
import { useFastDeliveryContext } from '@context/FastDeliveryContext';
import { useNetworkState } from '@hooks/useNetworkState';
import { useShoppingListsContext } from '@context/ShoppingListContext';
import CreateListDialog from '@components/Pages/ShoppingLists/components/CreateListDialog';
import {
  useAuth,
  useProductsUserDataLazyQuery,
  useShoppingListLineBulkUpdate,
} from 'teddly-sdk';
import useWindowDimensions from '@hooks/useWindowDimension';
import { ProductEvents, sendConvertedEvent } from '@config/algolia/utils';
import { ItemDescriptionDialogIDs } from '@cypress/components/dialogs/ItemDescription.cy';

import styles from './ProductDescription.module.scss';

function ProductDescription(): JSX.Element {
  const { router, navigateTo } = useRoutesContext();
  const [shoppingListLineBulkUpdate] = useShoppingListLineBulkUpdate();
  const { user } = useAuth();
  const { isLargeTablet } = useWindowDimensions();
  const { addCartVariant, removeCartVariant, getCartVariant } =
    useCartContext();
  const {
    closeDialog,
    openDialog: openDialog,
    addSnackbar,
    closeSnackbar,
    setLoading,
    openLoginDialog,
    currentProduct,
    line,
    disabledCounter,
    currentVariant,
    queryId,
    resultsIndexName,
    openProductDialog,
  } = usePageLayoutContext();

  const testIds = new ItemDescriptionDialogIDs();

  const { state, setCompleted, setError } = useNetworkState<string>();
  const { product: productId, vendorSlug } = router.query;
  const { setVendor } = useVendorContext();
  const { isFastDelivery } = useFastDeliveryContext();
  const { addList, ShoppingListsByVariantHandler, shoppingListsData } =
    useShoppingListsContext();
  const [isOpenCreateListDialog, setIsOpenCreateListDialog] = useState(false);
  const [
    { product: product },
    setState,
  ] = useState<{
    product: NewProduct | null;
    network_status: 'idle' | 'loading' | 'loaded' | 'error';
    errorMessage?: string;
    comboErrorMessage?: string;
  }>({
    product: null,
    network_status: 'idle',
  });

  const isDialogOpen =
    product?.variants && product?.id === productId && openDialog === 'product'
      ? true
      : false;
  // const [productsIds, setProductsIds] = useState<string[]>([productId as string])

  // const { data: productUserRequestData } = useProductsUserData({ ids: productsIds }, { fetchPolicy: "network-only", skip: !ids || !authenticated })

  const [fetchProductsUserData] = useProductsUserDataLazyQuery({
    fetchPolicy: 'network-only',
  });

  const showProductUserData = async () => {
    if (user && productId) {
      const response = await fetchProductsUserData({
        variables: { ids: [productId as string] },
        fetchPolicy: 'network-only',
      });
      const resData = response?.data?.productsUserData;
      if (resData?.length == 1) {
        const productUserData = resData[0];
        setState((prevState) => ({
          ...prevState,
          product: {
            ...prevState.product,
            lastOrdered: productUserData?.lastOrdered,
            ordersQuantity: productUserData?.ordersQuantity,
          },
        }));
      }
    }
  };
  useEffect(() => {
    if (
      productId &&
      product &&
      (!product?.lastOrdered || !product?.ordersQuantity)
    ) {
      showProductUserData();
    }
  }, [productId, isDialogOpen]);

  useEffect(() => {
    if (productId) {
      setLoading(true);
      (async () => {
        setState((prevState) => ({ ...prevState, network_status: 'loading' }));
        const newProduct: NewProduct =
          currentProduct || (await getProductById(productId as string));
        if (newProduct) {
          if (vendorSlug) {
            newProduct.variants = newProduct.variants.filter(
              (v) => v.vendor.slug == vendorSlug,
            );
          }
          if (isFastDelivery)
            newProduct.variants = newProduct.variants.filter(
              (v) => v.is_fast_delivery,
            );
          newProduct.variants = newProduct.variants.map((item) => {
            const variant = getCartVariant(item.id) || item;
            variant.line = variant.line || {};
            return variant;
          });
          setState((prevState) => ({
            ...prevState,
            network_status: 'loaded',
            product: newProduct,
          }));
          ShoppingListsByVariantHandler(newProduct?.variants[0].id);
        } else {
          const snackbarId: string = `${productId}-not-found`;
          addSnackbar({
            id: snackbarId,
            onClose: () => closeSnackbar(snackbarId),
            message: 'Product Not Found',
            variant: SnackbarVariant.ERROR,
          });

          closeDialog();
        }
        setLoading(false);
      })();
    }
  }, [productId]);

  const onClose = (navigateToLastPath: boolean = true) => {
    closeDialog(navigateToLastPath);
    setTimeout(function () {
      setState((prevState) => ({
        ...prevState,
        network_status: 'idle',
        product: null,
      }));
    }, 300);
  };

  const onPlus = async (variant: NewVariant, value: number) => {
    if (value === 1 && queryId && resultsIndexName) {
      sendConvertedEvent({
        objectID: product?.id,
        userId: user?.id,
        eventName: ProductEvents.ADDED_TO_CART,
        indexName: resultsIndexName,
        queryID: queryId,
      });
    }
    // variant.line.quantity = value;
    if (line) {
      await shoppingListLineBulkUpdate({
        lines: [{ id: line.id, quantity: value }],
      });
    } else {
      addCartVariant(variant, value);
    }
  };

  const selectedVariant = line?.variant || currentVariant;

  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const firstDiv = divRef.current.firstElementChild;
      if (firstDiv) {
        firstDiv.style.margin = '0px';
      }
    }
  }, [divRef.current]);

  const getStrHtmlAsJsxElement = (strHtml: string) => {
    return (
      <div
        ref={divRef}
        className={styles.descriptionClick}
        dangerouslySetInnerHTML={{
          __html: strHtml
            .replace(/<div.*?>/g, '<div>')
            .replace(/<\/div>/g, '</div>'),
        }}
      />
    );
  };

  const getDescription = () => {
    if (product?.description_block) {
      return getStrHtmlAsJsxElement(product.description_block);
    }

    return product?.description;
  };

  return (
    <>
      <ItemDescription
        closeButtonTestId={testIds.closeBtnID}
        bodyTestId={testIds.bodyID}
        footerTestId={testIds.footerID}
        headerTestId={testIds.headerID}
        currentVariant={selectedVariant}
        disabledCounter={(line || currentVariant) && disabledCounter}
        lastPurchase={product?.lastOrdered}
        amountOfPurchases={product?.ordersQuantity}
        addToCartButtonTestId={testIds.addToCartBtnID}
        arrowBackButtonTestId={testIds.arrowBackBtnID}
        arrowNextButtonTestId={testIds.arrowNextBtnID}
        // breadcrumbCategoriesDataTestId={testIds.}
        // byQuantitySelectorTestId={testIds.qtyDropdownOptionID}
        // commentDialogCancelButtonDataTestId={ids.commentDialogCancelButton}
        // commentDialogSaveButtonDataTestId={ids.commentDialogSaveButton}
        commentToVendorButtonTestId={testIds.messageToVendorBtnID}
        // commentToVendorInputDataTestId={ids.commentToVendorInput}
        // createNewListButtonDataTestId={ids.createNewListButton}
        // itemImageDataTestId={testIds}
        // itemMenuButtonDataTestId={ids.itemMenuButton}
        // nameDataTestId={testIds.n}
        // priceDataTestId={testIds.pr}
        // quantityControllerDataTestId={testIds}
        shareButtonTestId={testIds.shareBtnID}
        addToShoppingListButton={testIds.addToShoppingListBtnID}
        // shoppingListsDataTestId={ids.shoppingLists}
        vendorLogoButtonTestId={testIds.vendorLogoID}
        shopping_lists={!user ? null : shoppingListsData}
        isMobile={isLargeTablet}
        bottomElement={
          product && (
            <ProductsListWidget
              scrollableTargetId="itemDescriptionContainer"
              showFilters={false}
              title="Popular items"
              hitsPerPage={12}
              readMoreButtonTestId={testIds.readMoreBtnID}
              fromCollection={
                product?.collections && product.collections.length
                  ? product.collections[0].slug
                  : null
              }
              fromCategory={
                product?.categories
                  ? product?.categories[product?.categories?.length - 1]?.slug
                  : null
              }
              excludingSku={product.sku}
              // fromDepartment={product.department ? product.department: null}
              fromVendor={vendorSlug as string}
              clickToLoad={true}
            />
          )
        }
        onChangeVariant={(value) => {
          openProductDialog({
            product_id: product.id,
            product: { ...product },
            currentVariant: value,
          });
          ShoppingListsByVariantHandler(value.id);
        }}
        onShare={async (e) => {
          try {
            if (window !== undefined && window.location.href) {
              await navigator.clipboard.writeText(window.location.href);
              setCompleted('Link copied to clipboard successfully!');
              addSnackbar({
                id: 'successSnackbar',
                onClose: () => closeSnackbar('successSnackbar'),
                message: 'Link copied to clipboard successfully!',
                variant: SnackbarVariant.SUCCESS,
              });
            } else {
              throw 'Error accured while copying to clipboard';
            }
          } catch (e) {
            setError(e);
            addSnackbar({
              id: 'errorSnackbar',
              onClose: () => closeSnackbar('errorSnackbar'),
              message: state.error || 'failed',
              variant: SnackbarVariant.ERROR,
            });
          }
        }}
        collections={product?.collections}
        supervisions={product?.supervisions}
        isOpen={isDialogOpen}
        onPlus={onPlus}
        onMinus={async (variant: NewVariant, value: number) => {
          variant.line.quantity = value;
          if (line) {
            await shoppingListLineBulkUpdate({
              lines: [{ id: line.id, quantity: value }],
            });
          } else {
            addCartVariant(variant, value);
          }
        }}
        onNoteChange={(variant: NewVariant, note: string) => {
          variant.line.note = note;
          addCartVariant(variant, variant?.line?.quantity);
        }}
        onClose={onClose}
        variants={product?.variants}
        name={product?.name || ''}
        description={getDescription()}
        brand={product?.brand}
        categories={product?.categories?.map((category, index) => {
          return {
            name: category.name,
            onClick: () => {
              if (vendorSlug) {
                navigateTo({
                  route: `/stores/${vendorSlug}?category=${category.slug}`,
                  options: {
                    shallow: true,
                  },
                });
              } else {
                navigateTo({
                  route: product?.categories
                    .slice(0, index + 1)
                    .map((value) => value.slug)
                    .join('/'),
                  options: { shallow: true },
                });
              }
              onClose(false);
            },
          };
        })}
        onVendorClick={async (clickedVendor: VendorDetails) => {
          setVendor(clickedVendor);
          await navigateTo({
            route: `/stores/${clickedVendor?.slug}`,
          });
          onClose();
        }}
        onCreateNewList={() => {
          if (!user) {
            openLoginDialog();
          } else {
            setIsOpenCreateListDialog(true);
          }
        }}
      />
      {isOpenCreateListDialog && (
        <CreateListDialog
          isOpen={isOpenCreateListDialog}
          closeDialog={() => {
            setIsOpenCreateListDialog(false);
          }}
          isFromItemDescription={true}
          submit={(name: string, quantity: number) => {
            addList(name, quantity);
            addSnackbar({
              id: 'listSnackbar',
              onClose: () => closeSnackbar('listSnackbar'),
              message: 'List successfully created',
              variant: SnackbarVariant.SUCCESS,
            });
            setIsOpenCreateListDialog(false);
          }}
        />
      )}
    </>
  );
}

export default ProductDescription;

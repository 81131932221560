import { Dialog, DialogIDs } from './Dialog.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';

const {
  buttons: btn,
  inputFields: field,
  components,
} = utilsData.elementsDataTestIDs;

export default class ForgotPasswordDialogIDs extends DialogIDs {
  emailInputID: string;
  forgotPasswordSubmitBtnID: string;
  backToLoginBtnID: string;

  constructor() {
    super(components.dialogs.forgotPassword);
    this.emailInputID = TestIDFormat.inputField(this.fullPrefix, field.email);
    this.forgotPasswordSubmitBtnID = TestIDFormat.button(
      this.fullPrefix,
      `${btn.forgotPassword}-${btn.submit}`,
    );
    this.backToLoginBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.backToLogin,
    );
  }
}

export class ForgotPasswordDialog extends Dialog<ForgotPasswordDialogIDs> {
  constructor() {
    super(ForgotPasswordDialogIDs);
  }

  get emailInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.emailInputID, timeout);
    };
  }

  get forgotPasswordSubmitBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.forgotPasswordSubmitBtnID, timeout);
    };
  }

  get backToLoginBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.backToLoginBtnID, timeout);
    };
  }
}

import gql from 'graphql-tag';

import { getTeddlySdkApi } from '@config/teddly-sdk';
import { CategoryItem } from '@interfaces';

import { CategoryDetailData } from './types';

const QUERY = gql`
  fragment CategoryDetail on Category {
    id
    name
    slug
    isItHaveVariantsVisibleInFront
    vendors {
      totalCount
    }
    backgroundImage(size: 255) {
      url
      alt
    }
    metadata {
      key
      value
    }
  }

  query CategoryMenuOf($slug: String) {
    category(includeAllChannels: true, slug: $slug) {
      ...CategoryDetail
      children(first: 100) {
        edges {
          node {
            ...CategoryDetail
            children(first: 100) {
              edges {
                node {
                  ...CategoryDetail
                }
              }
            }
          }
        }
      }
    }
  }
`;

function transformMenuQueryResult(
  data: CategoryDetailData['children'],
): CategoryItem[] {
  const items: CategoryItem[] = [];
  data?.edges?.forEach(({ node }) => {
    const { id, name, slug, backgroundImage, channel } = node;
    // const vendors_count = node.vendors.totalCount;
    const imgSrc = backgroundImage ? backgroundImage.url : '';
    if (node.isItHaveVariantsVisibleInFront) {
      items.push({
        id,
        name,
        slug,
        // vendors_count,
        imgSrc,
        channel,
        categories: transformMenuQueryResult(node.children),
      });
    }
  });
  return items;
}

export async function getCategoryDetails(
  slug: string,
): Promise<CategoryItem | null> {
  const { teddlyApolloClient } = await getTeddlySdkApi();

  return teddlyApolloClient
    .query({
      query: QUERY,
      variables: {
        slug,
      },
    })
    .then(({ data }) => {
      const { category }: { category: CategoryDetailData } = data;

      if (category && category.isItHaveVariantsVisibleInFront) {
        return {
          id: category.id,
          name: category.name,
          slug: category.slug,
          channel: category.channel,
          // vendors_count: category.vendors.totalCount,
          imgSrc: category.backgroundImage ? category.backgroundImage.url : '',
          categories: transformMenuQueryResult(category.children),
        };
      }

      return null;
    });
}

export async function getRootCategoriesBaseFragments(): Promise<
  { name: string; slug: string }[]
> {
  const { teddlyApolloClient } = await getTeddlySdkApi();
  const observable = teddlyApolloClient.watchQuery({
    query: gql`
      query {
        categories(first: 10, level: 0) {
          edges {
            node {
              slug
              name
            }
          }
        }
      }
    `,
    fetchPolicy: 'cache-and-network',
  });

  const { data } = observable.getCurrentResult();
  const nodes = data.categories.edges.map(({ node }) => node);
  return nodes;
}


// export async function getRootCategoriesBaseFragments(): Promise<
//   { name: string; slug: string }[]
// > {
//   const { teddlyApolloClient } = await getTeddlySdkApi();
//   const { data } = await teddlyApolloClient.watchQuery({
//     query: gql`
//       query {
//         categories(first: 10, level: 0) {
//           edges {
//             node {
//               slug
//               name
//             }
//           }
//         }
//       }
//     `,
//     fetchPolicy: 'cache-and-network',
//   });
//   // .then(({ data }) => {
//   //   const nodes = data.categories.edges.map(({ node }) => node);
//   //   return nodes;
//   // });
// }

export async function getRootCategoriesSlugs(): Promise<string[]> {
  const response = await getRootCategoriesBaseFragments();
  return response.map((node) => node.slug);
}

export async function getRootCategoriesData(): Promise<CategoryItem[]> {
  const { teddlyApolloClient } = await getTeddlySdkApi();

  return teddlyApolloClient
    .query({
      query: gql`
        query {
          categories(first: 10, level: 0, includeAllChannels: true) {
            edges {
              node {
                id
                name
                slug
                isItHaveVariantsVisibleInFront
                backgroundImage {
                  url
                  alt
                }
                metadata {
                  key
                  value
                }
              }
            }
          }
        }
      `,
    })
    .then(({ data }) => {
      const items: CategoryItem[] = [];
      data.categories.edges.forEach(({ node }) => {
        const { id, name, slug, backgroundImage } = node;
        // const vendors_count = node.vendors.totalCount;
        const imgSrc = backgroundImage ? backgroundImage.url : '';
        items.push({
          id,
          name,
          slug,
          // vendors_count,
          imgSrc,
          categories: [],
        });
      });
      return items;
    });
}

export async function getVendorSubCategoriesData(
  vendorSlug: string,
): Promise<CategoryItem[]> {
  const { teddlyApolloClient } = await getTeddlySdkApi();

  return teddlyApolloClient
    .query({
      query: gql`
      query{
        categories(first:100 level: 1 filter: {activeVendor: "${vendorSlug}"}, includeAllChannels: true){
          totalCount
          edges{
            node{
              name
              slug
              backgroundImage(size: 255) {
                url
                alt
              }
              children(first: 100, filter: {activeVendor: "${vendorSlug}"}){
                edges{
                  node{
                    slug
                    name
                    backgroundImage(size: 255) {
                      url
                      alt
                    }
                  }
                }
              }
            }
          }
        }
      }
      `,
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      const items: CategoryItem[] = [];
      data.categories.edges.forEach(({ node }) => {
        const { parent, name, slug, backgroundImage, children } = node;
        const imgSrc = backgroundImage?.url ? backgroundImage.url : null;
        items.push({
          name,
          slug,
          imgSrc,
          parent: { slug: parent?.slug },
          categories: children?.edges.map((child) => {
            return {
              slug: child.node.slug,
              name: child.node.name,
              imgSrc: child?.node?.backgroundImage?.url,
            };
          }),
        });
      });
      return items;
    });
}

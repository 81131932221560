import {
  AddressTypeEnum,
  CreateUserAddressMutation,
  CreateUserAddressMutationVariables,
} from 'teddly-sdk';

import { useUserAddressesContext } from '@context/UserAddressContext';

import ShippingAddressForm from '../../components/ShippingAddressForm';
import { CurrentOpenDialog } from '@context/CheckoutPageContext';
import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { AddDeliveryAddressDialogIDs } from '@cypress/components/dialogs/AddDeliveryAddress.cy';

export default function AddAddressForm({
  addressType,
}: {
  addressType: AddressTypeEnum;
}) {
  const { addAddress } = useUserAddressesContext();

  const testIds = new AddDeliveryAddressDialogIDs();

  const {
    closeCurrentDialog,
    currentOpenDialog,
    openPickAddressForm,
    setSelectedShippingAddress: setSelectedAddress,
    setIsShippingLoading,
    isShippingLoading,
  } = useCheckoutShippingAddressContext();

  const { addSnackbar, closeSnackbar } = usePageLayoutContext();

  const handleSubmit = async (
    address: CreateUserAddressMutationVariables['input'],
    isDefault: boolean,
  ): Promise<CreateUserAddressMutation | null> => {
    const vars: CreateUserAddressMutationVariables = {
      input: address,
      type: addressType,
      isDefault: isDefault,
    };
    // if (type) vars.type = type;
    setIsShippingLoading(true);
    try {
      const response = await addAddress(vars);
      const data = response?.accountAddressCreate;
      if (data && data?.user?.id) {
        // const chosen = data?.user?.addresses[data?.user?.addresses.length - 1];
        const chosen = data?.data;
        setSelectedAddress(chosen);
        setIsShippingLoading(false);
        addSnackbar({
          id: 'success',
          onClose: () => closeSnackbar('success'),
          message: 'Delivery Address is added successfully!',
          variant: SnackbarVariant.SUCCESS,
          testId: testIds.snackbarID.successID,
        });
        closeCurrentDialog();
      } else if (data && data.errors && data.errors.length) {
        addSnackbar({
          id: 'error',
          onClose: () => closeSnackbar('error'),
          message: 'Something went wrong',
          variant: SnackbarVariant.ERROR,
          testId: testIds.snackbarID.errorID,
        });
        setIsShippingLoading(false);
      }
      return response;
    } catch (e) {
      setIsShippingLoading(false);
      addSnackbar({
        id: 'error',
        onClose: () => closeSnackbar('error'),
        message: 'Something went wrong',
        variant: SnackbarVariant.ERROR,
        testId: testIds.snackbarID.errorID,
      });
    }
  };

  return (
    <>
      <ShippingAddressForm
        testIds={testIds}
        isOpen={currentOpenDialog === CurrentOpenDialog.AddAddressForm}
        onClose={() => {
          closeCurrentDialog();
          openPickAddressForm();
        }}
        onSubmit={handleSubmit}
        loading={isShippingLoading}
        backendErrors={[]}
      />
    </>
  );
}

import { useEffect, useState } from 'react';
import { useConnector } from 'react-instantsearch-hooks-web';
import connectAutocomplete from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';
import { AlgoliaIndexes, QuerySuggestionsWrapper } from '@config/algolia';
import { debounce } from 'lodash';
import {
  SearchField,
  SearchFieldVariantEnum,
  SearchFieldTypeEnum,
} from '@teddly/teddly-ui-components';
import { SearchBarProps } from '@interfaces';
import { useVendorContext } from '@context/VendorContext';
import { useRoutesContext } from '@context/RoutesContext';
import { useFastDeliveryContext } from '@context/FastDeliveryContext';
import { ProductsListWidget } from '@app/products/ProductListWidget';
import styles from './Search.module.scss';
import { useHomePageContext } from '@context/HomePageContext';
import useWindowDimensions from '@hooks/useWindowDimension';
import { getGoogleMapsLocationUrl, getServerAssetsImageUrl } from '@utils';
import { useChannelContext } from '@context/ChannelContext';
import { usePageLayoutContext } from '@context/PageLayoutContext';
import { useTranslation } from 'next-i18next';
import { LandingPageIDs } from '@cypress/components/pages/Landing.cy';

interface IProps {
  key?: string;
  isInHeader?: boolean;
  isWithStrip?: boolean;
  searchInputTestId: string;
  searchButtonTestId: string;
  variant: SearchFieldVariantEnum;
  onClick?: () => void;
  isSearchPage?: boolean;
  stretch?: boolean;
  largeVariantPaddingLeftOfBottomComponent?: number;
  valueInBottomOfSearchBar?: JSX.Element
  defaultValue?: string;
}

export function useAutocomplete(props?) {
  return useConnector(connectAutocomplete, props);
}

export const WithSearchBarType = ({
  variant,
  defaultValue,
  onClick,
  searchInputTestId,
  searchButtonTestId,
  isWithStrip,
  valueInBottomOfSearchBar,
  ...othersProps
}: IProps) => {
  const { indices, currentRefinement, refine } = useAutocomplete();
  const { vendor } = useVendorContext();
  const { isFastDelivery } = useFastDeliveryContext();
  const { setIsSearchFieldVisible, isSearchFieldVisible } =
    useHomePageContext();
  // const [loadChannelByAddress, { called, data: channelByAddressData, networkStatus, refetch }] = useChannelByAddressLazyQuery({ variables: { address: { city: "Baton Rouge", country: CountryCode.US, cityArea: "LA", postalCode: "70802" } } })
  // const { selectChannelByAddress } = useAuth();

  const { isLargeTablet } = useWindowDimensions();
  const { isChannelSelected, selectChannelByPostalCode } = useChannelContext();
  const { openLoginDialog, setLoading } = usePageLayoutContext();
  const landingPageIDs = new LandingPageIDs();

  const { router, navigateTo } = useRoutesContext();
  const { q } = router.query;
  const [searchInput, setSearchInput] = useState<string>(defaultValue);
  // const [locationInput, setLocationInput] = useState<string>('dsf');
  const refineFunction = (value: string) => {
    if (value) (refine as () => void)(value);
  };

  const [suggestions, setSuggestions] = useState<any>([]);
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState(false);
  const [isErrored, setIsErrored] = useState(false);
  const { t } = useTranslation('common', { keyPrefix: 'search' });

  useEffect(() => {
    const results = (indices as any) ?? [];
    if (
      currentRefinement &&
      results &&
      results.length > 0 &&
      results[0].results?.query == currentRefinement
    ) {
      setSuggestions(indices as any);
    }
  }, [indices]);

  useEffect(() => {
    refineFunction(q as string);
  }, [q, refine]);
  const properties: SearchBarProps = {
    onChange: refineFunction,
    value: currentRefinement as string,
    // errorState: currentRefinement && !indicesObject?.hits?.length,
    onEnter: async (value) => {
      if (value) {
        navigateTo({
          route: vendor
            ? `/stores/${vendor.slug}/search?q=${value}`
            : `/search?q=${value}`,
          options: { scroll: true },
        });
        if (onClick) onClick();
      }
      setSearchInput('');
      setSuggestions([]);
    },
    showSuggestions: false,
    // currentRefinement && q !== currentRefinement && !!indicesObject.hits.length,
  };

  // const onLocationInputChange = (value: string) => setLocationInput(value)

  const [searchLocationLoading, setSearchLocationLoading] = useState(false);
  const [postalCode, setPostalCode] = useState<string>('');

  const onLocationInputChanged = (value: string) => {
    const regex = /[0-9]/;
    if (
      value?.length <= postalCode?.length ||
      regex.test(value[value?.length - 1])
    ) {
      setPostalCode(value);
    }
  };

  const onLocationSearchClick = async (postalCode: string) => {
    setSearchLocationLoading(true);
    const selectedChannel = await selectChannelByPostalCode(postalCode);
    setSearchLocationLoading(false);
    if (!selectedChannel) {
      setSearchLocationLoading(false);
    }
    setIsErrored(!selectedChannel);
    const returnUrl = router.query?.returnUrl;
    if (returnUrl) {
      navigateTo({
        route: returnUrl?.toString(),
      });
    }
  };

  const getAddressComponentFieldValue = (
    addressComponent: object,
    fields: string[],
    shortName: boolean = false,
  ) => {
    const nameLengthType = shortName ? 'short_name' : 'long_name';
    let selectedField: object | any = null;
    fields.forEach((field) => {
      const found = Object.values(addressComponent).find((node: any) =>
        node?.types?.includes(field),
      );
      if (found) {
        selectedField = found;
        return;
      }
    });
    // Object.values(addressComponent).find((node: any) => node?.types?.some(type => fields?.includes(type)))
    if (selectedField) {
      return selectedField[nameLengthType];
    }
  };

  const getAddressFromLocationAndSendRequest = async (
    latitude: number,
    longitude: number,
  ) => {
    // const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=en`;
    // const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&language=en`
    const response = await fetch(getGoogleMapsLocationUrl(latitude, longitude));
    const data = await response.json();
    const resultsList = data?.results;
    const firstAddress = resultsList[0]?.address_components;
    if (firstAddress) {
      const postalCode = getAddressComponentFieldValue(firstAddress, [
        'postal_code',
      ]);
      if (postalCode) {
        onLocationSearchClick(postalCode);
      } else {
        setIsErrored(true);
        setLoading(false);
      }
    }
  };

  const getLocationPositionAndSendRequestForChannel = (
    position: GeolocationPosition,
  ) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    getAddressFromLocationAndSendRequest(latitude, longitude);
  };

  const selectChannelByLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        getLocationPositionAndSendRequestForChannel,
      );
    } else {
      console.log('Geolocation is not available in your browser.');
    }
  };

  useEffect(() => {
    if (!isChannelSelected) {
      selectChannelByLocation();
    }
  }, []);

  const searchFieldType = isChannelSelected
    ? SearchFieldTypeEnum.PRODUCTS
    : SearchFieldTypeEnum.LOCATION;

  const onChangeHandler = (value) => {
    if (value == '') {
      setSuggestions([]);
    }
    setSearchInput(value);
    debounceOnChangeHandler(value);
  };

  const debounceOnChangeHandler = debounce((value) => {
    properties.onChange(value);
  }, 300);

  const onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      if (searchFieldType === SearchFieldTypeEnum.PRODUCTS) {
        properties.onEnter(searchInput);
      } else {
        onLocationSearchClick(postalCode);
      }
    }
  };
  const searchAtTitles = [];
  if (vendor) searchAtTitles.push(vendor?.name);
  if (isFastDelivery) searchAtTitles.push('Teddly now');
  const title =
    searchAtTitles.length > 0
      ? `Search @${searchAtTitles.join(' ')}`
      : t('place-holder');
  return (
    <SearchField
      shippersImage={getServerAssetsImageUrl('teddly-delivery.png')}
      belowBackgroundImage={getServerAssetsImageUrl('city-background.png')}
      notFoundImage={getServerAssetsImageUrl('not-found.png')}
      searchType={searchFieldType}
      searchInputTestId={searchInputTestId}
      searchButtonTestId={searchButtonTestId}
      locationInputTestId={landingPageIDs.zipCodeInputID}
      locationButtonTestId={landingPageIDs.zipCodeSearchBtnID}
      signInForSavedChannelButtonTestId={landingPageIDs.signInBtnID}
      stretch
      type={'text'}
      showBarcodeScanner={isLargeTablet}
      isSearchFieldVisible={isSearchFieldVisible}
      isSuggestionsOpen={
        searchInput &&
        suggestions.length > 0 &&
        suggestions[0].hits.length > 0 &&
        isSuggestionsOpen
      }
      // disabled={isChannelSelected ? !}
      // locationInputComponent={locationAutoAutocompleteInput}
      errored={isErrored}
      onLoginClick={openLoginDialog}
      onLocationInputChange={onLocationInputChanged}
      locationValue={postalCode}
      searchLocationLoading={searchLocationLoading}
      onLocationSearchClick={() => onLocationSearchClick(postalCode)}
      setIsSuggestionsOpen={setIsSuggestionsOpen}
      value={searchInput}
      isWithStrip={isWithStrip}
      placeholder={title}
      valueInBottomOfSearchBar={valueInBottomOfSearchBar}
      // largeVariantPaddingLeftOfBottomComponent={largeVariantPaddingLeftOfBottomComponent}
      label={title}
      onChange={onChangeHandler}
      onKeyPress={onKeyPressHandler}
      onClick={(value) => properties.onEnter(value)}
      variant={variant}
      setIsSearchFieldVisible={setIsSearchFieldVisible}
      suggestions={
        suggestions.length > 0 ? suggestions[0].hits?.map((v) => v.query) : []
      }
      suggestionsButtonTitle={t('btn-more')}
      onSuggestionsButtonClick={() => properties.onEnter(searchInput)}
      {...othersProps}>
      {suggestions.length > 0 && suggestions[0].hits.length > 0 && (
        <ProductsListWidget
          className={
            variant === SearchFieldVariantEnum.LARGE
              ? styles.largeSuggestionItemsList
              : ''
          }
          itemClassName={styles.itemClassName}
          maxHits={2}
          isVertical={variant === SearchFieldVariantEnum.DENSE}
          query={suggestions[0].hits[0]?.query}
          fromVendor={vendor ? vendor?.slug : null}
          showFilters={false}
        />
      )}
    </SearchField>
  );
};

export const SearchBar = (props: IProps) => {
  return (
    <QuerySuggestionsWrapper indexName={AlgoliaIndexes.PRODUCTS_QUERY}>
      <WithSearchBarType {...props} />
    </QuerySuggestionsWrapper>
  );
};

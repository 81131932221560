import { StoredPaymentSources_me_storedPaymentSources } from 'teddly-sdk/lib/queries/gqlTypes/StoredPaymentSources';

import {
  Trash2 as TrashIcon,
  InfiniteList,
  UserInfoBlock,
  Button,
  ButtonVariantEnum,
  ButtonSizeEnum,
  IconButton,
} from '@teddly/teddly-ui-components';
import { css } from 'twin.macro';
import { ids } from '../../../../../cypress/pages/checkout/CheckoutPage.cy';
import styles from './style.module.scss';
import { Address } from 'teddly-sdk';
import useWindowDimensions from '@hooks/useWindowDimension';
import React from 'react';
import { StripeCardData } from '../hooks';
import { UserAddressType } from '@context/UserAddressContext';
import { PaymentMethodCardIDs } from '@cypress/components/cards/PaymentMethod.cy';
import { DeliveryAddressCardIDs } from '@cypress/components/cards/DeliveryAddress.cy';

type Methods = StripeCardData[] | UserAddressType[];

type MethodsListProps = {
  blockTitle: string;
  descriptionFormatter: (object: any) => string;
  methods?: Methods;
  onSelectCard?: (method) => Promise<void>;
  onEditCard?: (addressToEdit?: UserAddressType) => void;
  onDeleteCard?: (id: string) => Promise<void>;
  selectedMethodId?: string;
  deletedMethodIds?: string[];
  firstElementTestIds?: PaymentMethodCardIDs | DeliveryAddressCardIDs;
};

export default function MethodsList({
  blockTitle,
  descriptionFormatter,
  methods = [],
  onSelectCard,
  onEditCard,
  onDeleteCard,
  selectedMethodId,
  deletedMethodIds,
  firstElementTestIds,
  ...otherProps
}: MethodsListProps) {
  const renderButtons = (method, index: number) => {
    const buttons = [];
    if (onEditCard) {
      buttons.push(
        <Button
          title="Edit"
          testId={index === 0 ? firstElementTestIds?.changeBtnID : null}
          variant={ButtonVariantEnum.OUTLINE}
          size={ButtonSizeEnum.SMALL}
          onClick={async (e) => {
            e.stopPropagation();
            await onEditCard(method);
          }}
        />,
      );
    }
    if (onDeleteCard) {
      const cardId = method?.creditCardInfo?.cardId || method?.id;
      const id = cardId
        ? cardId.startsWith('{')
          ? (function () {
              const cleaned = cardId.slice(1, -1).replace(/'/g, '').trim();
              const parts = cleaned.split(',').map((part) => part.trim());
              return parts[1] || null;
            })()
          : cardId
        : null;
      const loading = deletedMethodIds.includes(id);
      buttons.push(
        <IconButton
          loading={loading}
          testId={index === 0 ? firstElementTestIds?.deleteBtnID : null}
          onClick={(e) => {
            e.stopPropagation();
            onDeleteCard(id);
          }}
          icon={<TrashIcon />}
        />,
      );
    }

    return buttons;
  };
  const { isLargeTablet } = useWindowDimensions();
  const renderHandler = (c: StripeCardData | UserAddressType, index) => {
    const method = c as any;
    const testIdsProps =
      index === 0
        ? {
            titleTestId: firstElementTestIds?.titleID,
            bodyTestId: firstElementTestIds?.bodyID,
            descriptionTestId: firstElementTestIds?.descriptionID,
          }
        : {};
    return (
      <UserInfoBlock
        {...testIdsProps}
        key={index}
        title={method?.tag?.name || blockTitle}
        data-testid={ids.shippingAddressName + method?.firstName}
        description={descriptionFormatter(method)}
        className={!isLargeTablet ? styles.userInfoMethodBlock : null}
        buttons={renderButtons(method, index)}
        onClick={() => onSelectCard(method)}
        selected={
          selectedMethodId &&
          selectedMethodId ==
            (method?.id ? method.id : method?.creditCardInfo?.cardId)
        }
      />
    );
  };

  return (
    methods && (
      <InfiniteList
        {...otherProps}
        css={css`
          gap: 24px;
        `}
        items={methods}
        rowRender={renderHandler}
      />
    )
  );
}

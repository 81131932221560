import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { IDialogIDs, IDialog } from '../../interfaces/IDialog.cy';
import TestObjectBase from '../TestObjectBase';
import { Snackbar, SnackbarIDs } from '../other-components/Snackbar.cy';

const {
  buttons: btn,
  namedElements: elm,
  components,
} = utilsData.elementsDataTestIDs;

export class DialogIDs implements IDialogIDs {
  headerID: string;
  titleID: string;
  bodyID: string;
  footerID: string;
  closeBtnID: string;
  snackbarID: SnackbarIDs;
  protected fullPrefix: string;

  constructor(prefix: string) {
    this.fullPrefix = `${prefix}-${components.dialogs.title}`;
    this.headerID = TestIDFormat.element(this.fullPrefix, elm.header);
    this.titleID = TestIDFormat.element(this.fullPrefix, elm.title);
    this.bodyID = TestIDFormat.element(this.fullPrefix, elm.body);
    this.footerID = TestIDFormat.element(this.fullPrefix, elm.footer);
    this.snackbarID = new SnackbarIDs(this.fullPrefix);
    this.closeBtnID = TestIDFormat.button(this.fullPrefix, btn.close);
  }
}

export class Dialog<T extends DialogIDs>
  extends TestObjectBase<T>
  implements IDialog
{
  constructor(DialogIDs) {
    super(new DialogIDs());
  }

  get header() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.headerID, timeout);
    };
  }

  get title() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.titleID, timeout);
    };
  }

  get body() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.bodyID, timeout);
    };
  }

  get footer() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.footerID, timeout);
    };
  }

  get snackbar() {
    return new Snackbar(this.testIDs.snackbarID);
  }

  get closeBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.closeBtnID, timeout);
    };
  }
}

import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import TestObjectBase from '../TestObjectBase';

const { unitController } = utilsData.elementsDataTestIDs.components;

export class ItemUnitControllerIDs {
  addBtnID: string;
  valueID: string;
  subtractBtnID: string;
  deleteBtnID: string;
  protected fullPrefix: string;

  constructor(prefix: string) {
    this.fullPrefix = `${prefix}-${unitController.title}`;
    this.valueID = TestIDFormat.element(this.fullPrefix, unitController.value);
    this.addBtnID = TestIDFormat.element(this.fullPrefix, unitController.add);
    this.deleteBtnID = TestIDFormat.element(
      this.fullPrefix,
      unitController.delete,
    );
    this.subtractBtnID = TestIDFormat.element(
      this.fullPrefix,
      unitController.subtract,
    );
  }
}

export class ItemUnitController extends TestObjectBase<ItemUnitControllerIDs> {
  constructor(itemUnitControllerIDs: ItemUnitControllerIDs) {
    super(itemUnitControllerIDs);
  }

  get Value() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.valueID, timeout);
    };
  }
  get add() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.addBtnID, timeout);
    };
  }
  get delete() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.deleteBtnID, timeout);
    };
  }
  get subtract() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.subtractBtnID, timeout);
    };
  }
}

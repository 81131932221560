import { PaymentGatewayType } from '@app/payment/types';
import { getAvailablePaymentGateway } from '@utils/paymentGateways';
import { useEffect, useState } from 'react';
import AuthorizeNetAddPaymentCard from '../components/PaymentCard/AuthorizeNetAddPayment';
import {
  AuthorizeNetAuthData,
  StripeAuthData,
  CardknoxAuthData,
} from '../context/checkout-flow';
import StripeAddPaymentCard from '../components/PaymentCard/StripeAddPayment';
import { useCheckoutPaymentContext } from '@context/CheckoutPaymentContext';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import CardknoxAddPaymentCard from '../components/PaymentCard/CardknoxPayment';
import { AddPaymentMethodDialogIDs } from '@cypress/components/dialogs/AddPaymentMethod.cy';

export function usePaymentGateway() {
  const { authData, handleBillingAddress, handleCardAdded } =
    useCheckoutPaymentContext();
  const { checkout } = useCheckoutFlowContext();
  const [paymentGateway, setPaymentGateway] =
    useState<PaymentGatewayType>(null);

  useEffect(() => {
    if (!paymentGateway && checkout?.availablePaymentGateways) {
      const paymentGateway = getAvailablePaymentGateway(
        checkout?.availablePaymentGateways,
      );
      setPaymentGateway(paymentGateway);
    }
  }, [checkout]);

  const testIds = new AddPaymentMethodDialogIDs();

  const renderPaymentForm = () => {
    if (!authData) return;
    switch (paymentGateway) {
      case PaymentGatewayType.STRIPE:
        return (
          <StripeAddPaymentCard
            testIds={testIds}
            onBillingAddress={handleBillingAddress}
            onAddPaymentCard={handleCardAdded}
            authData={authData as StripeAuthData}
          />
        );
      case PaymentGatewayType.CARDKNOX:
        return (
          <CardknoxAddPaymentCard
            testIds={testIds}
            onBillingAddress={handleBillingAddress}
            onAddPaymentCard={handleCardAdded}
          />
        );
      case PaymentGatewayType.AUTHORIZE:
        return (
          <AuthorizeNetAddPaymentCard
            testIds={testIds}
            onBillingAddress={handleBillingAddress}
            onAddPaymentCard={handleCardAdded}
            authData={authData as AuthorizeNetAuthData}
          />
        );
      default:
        break;
    }
  };

  return {
    paymentGateway,
    renderPaymentForm,
  };
}

import { Dialog, DialogIDs } from './Dialog.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';

const {
  buttons: btn,
  inputFields: input,
  components,
} = utilsData.elementsDataTestIDs;

export class AddPaymentMethodDialogIDs extends DialogIDs {
  billingAddressToggleID: string;
  addCardSubmitBtnIDs: string;
  firstNameInputID: string;
  lastNameInputID: string;
  cardNumberInputID: string;
  expirationInputID: string;
  cvvInputID: string;
  phoneNumberInputID: string;
  searchAddressInputID: string;
  apartmentInputID: string;
  streetAddressInputID: string;
  cityInputID: string;
  stateInputID: string;
  zipCodeInputID: string;

  constructor() {
    super(components.dialogs.addPaymentMethod);

    this.billingAddressToggleID = TestIDFormat.button(
      this.fullPrefix,
      btn.toggle,
    );

    this.addCardSubmitBtnIDs = TestIDFormat.button(
      this.fullPrefix,
      `${btn.addCard}-${btn.submit}`,
    );

    this.firstNameInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.firstName,
    );
    this.lastNameInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.lastName,
    );
    this.cardNumberInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.cardNumber,
    );
    this.expirationInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.expiration,
    );
    this.cvvInputID = TestIDFormat.inputField(this.fullPrefix, input.cvv);
    this.phoneNumberInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.phoneNumber,
    );
    this.searchAddressInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.addressSearch,
    );
    this.apartmentInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.apartment,
    );
    this.streetAddressInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.streetAddress,
    );
    this.cityInputID = TestIDFormat.inputField(this.fullPrefix, input.city);
    this.stateInputID = TestIDFormat.inputField(this.fullPrefix, input.state);
    this.zipCodeInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.zipCode,
    );
  }
}

export class AddPaymentMethodDialog extends Dialog<AddPaymentMethodDialogIDs> {
  constructor() {
    super(AddPaymentMethodDialogIDs);
  }

  get billingAddressToggle() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.billingAddressToggleID, timeout);
    };
  }

  get addCardSubmitBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.addCardSubmitBtnIDs, timeout);
    };
  }

  get firstNameInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.firstNameInputID, timeout);
    };
  }

  get lastNameInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.lastNameInputID, timeout);
    };
  }

  get cardNumberInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.cardNumberInputID, timeout);
    };
  }

  get expirationInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.expirationInputID, timeout);
    };
  }

  get cvvInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.cvvInputID, timeout);
    };
  }

  get phoneNumberInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.phoneNumberInputID, timeout);
    };
  }

  get searchAddressInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.searchAddressInputID, timeout);
    };
  }

  get apartmentInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.apartmentInputID, timeout);
    };
  }

  get streetAddressInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.streetAddressInputID, timeout);
    };
  }

  get cityInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.cityInputID, timeout);
    };
  }

  get stateInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.stateInputID, timeout);
    };
  }

  get zipCodeInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.zipCodeInputID, timeout);
    };
  }
}

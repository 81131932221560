import { Dialog, DialogIDs } from './Dialog.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';

const {
  buttons: btn,
  inputFields: field,
  components,
} = utilsData.elementsDataTestIDs;

export class ResetPasswordDialogIDs extends DialogIDs {
  currentPasswordInputFieldID: string;
  newPasswordInputFieldID: string;
  newPasswordConfirmInputFieldID: string;
  resetPasswordSubmitBtnID: string;
  cancelBtnID: string;

  constructor() {
    super(components.dialogs.resetPassword);

    this.currentPasswordInputFieldID = TestIDFormat.inputField(
      this.fullPrefix,
      field.currentPassword,
    );
    this.newPasswordInputFieldID = TestIDFormat.inputField(
      this.fullPrefix,
      field.newPassword,
    );
    this.newPasswordConfirmInputFieldID = TestIDFormat.inputField(
      this.fullPrefix,
      field.confirmNewPassword,
    );
    this.resetPasswordSubmitBtnID = TestIDFormat.button(
      this.fullPrefix,
      `${btn.resetPassword}-${btn.submit}`,
    );
    this.cancelBtnID = TestIDFormat.button(this.fullPrefix, btn.cancel);
  }
}

export class ResetPasswordDialog extends Dialog<ResetPasswordDialogIDs> {
  constructor() {
    super(ResetPasswordDialogIDs);
  }

  get currentPasswordInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.currentPasswordInputFieldID, timeout);
    };
  }

  get newPasswordInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.newPasswordInputFieldID, timeout);
    };
  }

  get newPasswordConfirmInput() {
    return (timeout?: number) => {
      return this.getElement(
        this.testIDs.newPasswordConfirmInputFieldID,
        timeout,
      );
    };
  }

  get resetPasswordSubmitBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.resetPasswordSubmitBtnID, timeout);
    };
  }

  get cancelBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.cancelBtnID, timeout);
    };
  }
}

import '@styles/index.scss';
import 'node_modules/@teddly/teddly-ui-components/dist/index.css';
import Head from 'next/head';
import { AppProps } from 'next/dist/shared/lib/router/router';
import { GoogleTagManager } from '@next/third-parties/google';
import PageLayout from '@components/Content/PageLayout';

import { useChannelContext } from '@context/ChannelContext';
import { useEffect, useState } from 'react';

function AppContent({ Component, pageProps }: AppProps) {
  const { optOutFromLayout, ...otherProps } = pageProps;

  const { channelDisplayName } = useChannelContext();

  const [title, setTitle] = useState('');
  const [favicon, setFavicon] = useState("/empty-favicon.ico");

  useEffect(() => {
    if (typeof window !== "undefined") {
      const currentHost = window.location.host;
      if (currentHost.includes("moishas.com")) {
        setTitle("Moishas supermarket");
        setFavicon("/moishas-logo.ico");
      } else {
        setTitle(channelDisplayName);
        setFavicon("/favicon.ico");
      }
    }
  }, [channelDisplayName]);
  

  return (
    <>
      <Head>
      <title>{title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        <link rel="alternate icon" href={`${favicon}?timestamp=${new Date().getTime()}`} />
        <link rel="icon" type="image/svg+xml" href="/favicon.png" />
        <GoogleTagManager gtmId="GTM-WNJX6ZW4" />
      </Head>
      {optOutFromLayout ? (
        <Component {...otherProps} />
      ) : (
        <PageLayout>
          <Component {...otherProps} />
        </PageLayout>
      )}
    </>
  );
}

export default AppContent;

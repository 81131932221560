import { Dialog, DialogIDs } from './Dialog.cy';
import { Collapsible, CollapsibleIDs } from '../collapsible/Collapsible.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';

const { buttons: btn, components } = utilsData.elementsDataTestIDs;

export class FilterSearchResultsDialogIDs extends DialogIDs {
  sortByCollapsibleIDs: CollapsibleIDs;
  brandCollapsibleIDs: CollapsibleIDs;
  vendorCollapsibleIDs: CollapsibleIDs;
  supervisionCollapsibleIDs: CollapsibleIDs;
  collectionsCollapsibleIDs: CollapsibleIDs;

  saveBtnID: string;
  clearBtnID: string;

  constructor() {
    super(components.dialogs.filterSearchResults);

    this.sortByCollapsibleIDs = new CollapsibleIDs(
      TestIDFormat.element(
        this.fullPrefix,
        components.collapsible.filterOptions.sortBy,
      ),
    );
    this.brandCollapsibleIDs = new CollapsibleIDs(
      TestIDFormat.element(
        this.fullPrefix,
        components.collapsible.filterOptions.brand,
      ),
    );
    this.vendorCollapsibleIDs = new CollapsibleIDs(
      TestIDFormat.element(
        this.fullPrefix,
        components.collapsible.filterOptions.vendor,
      ),
    );
    this.supervisionCollapsibleIDs = new CollapsibleIDs(
      TestIDFormat.element(
        this.fullPrefix,
        components.collapsible.filterOptions.supervision,
      ),
    );
    this.collectionsCollapsibleIDs = new CollapsibleIDs(
      TestIDFormat.element(
        this.fullPrefix,
        components.collapsible.filterOptions.collection,
      ),
    );

    this.saveBtnID = TestIDFormat.button(this.fullPrefix, btn.save);
    this.clearBtnID = TestIDFormat.button(this.fullPrefix, btn.clear);
  }
}

export class FilterSearchResultsDialog extends Dialog<FilterSearchResultsDialogIDs> {
  constructor() {
    super(FilterSearchResultsDialogIDs);
  }

  get sortByCollapsible() {
    return new Collapsible(this.testIDs.sortByCollapsibleIDs);
  }
  get brandCollapsible() {
    return new Collapsible(this.testIDs.brandCollapsibleIDs);
  }
  get vendorCollapsible() {
    return new Collapsible(this.testIDs.vendorCollapsibleIDs);
  }
  get supervisionCollapsible() {
    return new Collapsible(this.testIDs.supervisionCollapsibleIDs);
  }

  get saveBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.saveBtnID, timeout);
    };
  }

  get clearBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.clearBtnID, timeout);
    };
  }
}

import MethodsList from './MethodsList';
import { getAddressDescription } from '@utils/descriptions';
import { useState } from 'react';
import { useRoutesContext } from '@context/RoutesContext';
import { UserAddressType } from '@context/UserAddressContext';
import { DeliveryAddressCardIDs } from '@cypress/components/cards/DeliveryAddress.cy';

type ShippingMethodsListProps = {
  methods?: UserAddressType[];
  selectedMethodId?: string;
  deletedMethodId?: string;
  onDeleteMethod?: (id: string) => Promise<void>;
  onEditMethod?: (addressToEdit: UserAddressType) => void;
  onSelectMethod?: (id: string) => Promise<void>;
  firstElementTestIds?: DeliveryAddressCardIDs;
};

export default function ShippingMethodsList({
  methods = [],
  selectedMethodId,
  onSelectMethod,
  onEditMethod,
  onDeleteMethod,
  firstElementTestIds,
  ...otherProps
}: ShippingMethodsListProps) {
  const [deletedMethodIds, setDeletedMethodIds] = useState<string[]>([]);
  const { isCurrentPage, pages } = useRoutesContext();

  const selectMethodHandler = async (id: string) => {
    await onSelectMethod(id);
  };

  const deleteMethodHandler = async (id: string) => {
    setDeletedMethodIds((prev) => [...prev, id]);
    await onDeleteMethod(id);
    setDeletedMethodIds((prev) => {
      prev.filter((e) => e != id);
      return [...prev];
    });
  };
  return (
    <MethodsList
      {...otherProps}
      blockTitle="Shipping Method"
      descriptionFormatter={getAddressDescription}
      methods={methods?.filter((address) => address?.isValidToShipping)}
      deletedMethodIds={deletedMethodIds}
      selectedMethodId={selectedMethodId}
      onDeleteCard={deleteMethodHandler}
      firstElementTestIds={firstElementTestIds}
      // onSelectCard={selectMethodHandler}
      onSelectCard={
        !isCurrentPage[pages.MY_ACCOUNT] ? selectMethodHandler : null
      }
      onEditCard={onEditMethod}
    />
  );
}

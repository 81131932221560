import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { Page, PageIDs } from './Page.cy';

const {
  buttons: btn,
  inputFields: field,
  components,
} = utilsData.elementsDataTestIDs;

export class LandingPageIDs extends PageIDs {
  zipCodeInputID: string;
  zipCodeSearchBtnID: string;
  signInBtnID: string;

  constructor() {
    super(components.pages.landing);

    this.zipCodeInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.zipCode,
    );
    this.zipCodeSearchBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.zipCodeSearch,
    );
    this.signInBtnID = TestIDFormat.button(this.fullPrefix, btn.signIn);
  }
}

export class LandingPage extends Page<LandingPageIDs> {
  constructor() {
    super(LandingPageIDs);
  }

  get zipCodeInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.zipCodeInputID, timeout);
    };
  }

  get zipCodeSearchBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.zipCodeSearchBtnID, timeout);
    };
  }

  get signInBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.signInBtnID, timeout);
    };
  }
}

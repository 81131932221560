import { DialogIDs, Dialog } from './Dialog.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { ItemCollapsible, ItemCollapsibleIDs } from '../collapsible/Item.cy';

const {
  buttons: btn,
  namedElements: elm,
  components,
} = utilsData.elementsDataTestIDs;

export class CartDialogIDs extends DialogIDs {
  allItemsBtnID: string;
  fastDeliveryBtnID: string;
  itemCollapsibleID: ItemCollapsibleIDs;
  showBreakdownBtnID: string;
  totalDisplayID: string;
  checkoutBtnID: string;

  constructor() {
    super(components.dialogs.cart);
    this.checkoutBtnID = TestIDFormat.button(this.fullPrefix, btn.checkout);
    this.allItemsBtnID = TestIDFormat.button(this.fullPrefix, btn.allItems);
    this.fastDeliveryBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.fastDelivery,
    );
    this.showBreakdownBtnID = TestIDFormat.button(
      this.fullPrefix,
      btn.showBreakdown,
    );
    this.totalDisplayID = TestIDFormat.element(this.fullPrefix, elm.total);
  }
}

export class CartDialog extends Dialog<CartDialogIDs> {
  constructor() {
    super(CartDialogIDs);
  }

  get checkoutBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.checkoutBtnID, timeout);
    };
  }

  get allItemsBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.allItemsBtnID, timeout);
    };
  }

  get fastDeliveryBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.fastDeliveryBtnID, timeout);
    };
  }

  get showBreakdownBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.showBreakdownBtnID, timeout);
    };
  }

  get totalDisplay() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.totalDisplayID, timeout);
    };
  }
  get itemCollapsible() {
    return new ItemCollapsible(this.testIDs.itemCollapsibleID);
  }
}

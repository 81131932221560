import { Dialog, DialogIDs } from './Dialog.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';

const {
  buttons: btn,
  inputFields: input,
  components,
} = utilsData.elementsDataTestIDs;

export class AddDeliveryAddressDialogIDs extends DialogIDs {
  makeDefaultToggleID: string;
  addressTypeChipID: string;
  addAddressBtnID: string;
  cancelBtnID: string;
  firstNameInputID: string;
  lastNameInputID: string;
  phoneNumberInputID: string;
  searchAddressInputID: string;
  apartmentInputID: string;
  streetAddressInputID: string;
  cityInputID: string;
  stateInputID: string;
  zipCodeInputID: string;
  noteInputID: string;

  constructor() {
    super(components.dialogs.addDeliveryAddress);

    this.makeDefaultToggleID = TestIDFormat.button(this.fullPrefix, btn.toggle);
    this.addressTypeChipID = TestIDFormat.button(this.fullPrefix, btn.chip);
    this.firstNameInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.firstName,
    );
    this.addAddressBtnID = TestIDFormat.button(this.fullPrefix, btn.addAddress);
    this.cancelBtnID = TestIDFormat.button(this.fullPrefix, btn.cancel);
    this.lastNameInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.lastName,
    );
    this.phoneNumberInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.phoneNumber,
    );
    this.searchAddressInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.addressSearch,
    );
    this.apartmentInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.apartment,
    );
    this.streetAddressInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.streetAddress,
    );
    this.cityInputID = TestIDFormat.inputField(this.fullPrefix, input.city);
    this.stateInputID = TestIDFormat.inputField(this.fullPrefix, input.state);
    this.zipCodeInputID = TestIDFormat.inputField(
      this.fullPrefix,
      input.zipCode,
    );
    this.noteInputID = TestIDFormat.inputField(this.fullPrefix, input.note);
  }
}

export class AddDeliveryAddressDialog extends Dialog<AddDeliveryAddressDialogIDs> {
  constructor() {
    super(AddDeliveryAddressDialogIDs);
  }
  get makeDefaultToggle() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.makeDefaultToggleID, timeout);
    };
  }

  get addressTypeChip() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.addressTypeChipID, timeout);
    };
  }

  get addAddressBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.addAddressBtnID, timeout);
    };
  }

  get cancelBtn() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.cancelBtnID, timeout);
    };
  }

  get firstNameInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.firstNameInputID, timeout);
    };
  }

  get lastNameInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.lastNameInputID, timeout);
    };
  }

  get phoneNumberInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.phoneNumberInputID, timeout);
    };
  }

  get searchAddressInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.searchAddressInputID, timeout);
    };
  }

  get apartmentInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.apartmentInputID, timeout);
    };
  }

  get streetAddressInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.streetAddressInputID, timeout);
    };
  }

  get cityInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.cityInputID, timeout);
    };
  }

  get stateInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.stateInputID, timeout);
    };
  }

  get zipCodeInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.zipCodeInputID, timeout);
    };
  }

  get noteInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.noteInputID, timeout);
    };
  }
}

import { useState } from 'react';

import { AuthMethodEnum, useAuth } from 'teddly-sdk';

import LoginDialog from '@components/Organisms/LoginDialog';
import { formatGraphQLError } from '@utils';

import {
  PageDialog,
  SnackbarVariant,
  usePageLayoutContext,
} from '../../../../context/PageLayoutContext';
import { ErrorResponse, FunctionRunResponse } from 'teddly-sdk/lib/api/types';
import { DataErrorAuthTypes } from 'teddly-sdk/lib/api/Auth/types';
import { SignInDialogIDs } from '@cypress/components/dialogs/SignIn.cy';

export default function LoginFormController() {
  const { signIn, oauthSignIn } = useAuth();
  const {
    openDialog: openDialog,
    closeDialog,
    openSignUpDialog,
    openForgotPasswordDialog,
  } = usePageLayoutContext();
  const [errorMessage, setErrorMessage] = useState('');
  const loginDialogIds = new SignInDialogIDs();

  const { addSnackbar, closeSnackbar, setLoading } = usePageLayoutContext();

  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const isSocialCreateAnAccountWithoutChannel = (
    dataError: ErrorResponse<DataErrorAuthTypes>,
  ): boolean => {
    return (
      Array.isArray(dataError?.error) &&
      dataError?.error?.length > 0 &&
      dataError?.error[0]?.code === 'REQUIRED' &&
      dataError?.error[0]?.field === 'channel'
    );
  };
  const onLoginFailed = (dataError: ErrorResponse<DataErrorAuthTypes>) => {
    if (!isSocialCreateAnAccountWithoutChannel(dataError)) {
      addSnackbar({
        id: 'error',
        onClose: () => closeSnackbar('error'),
        message: errorMessage || 'Email or Password is incorrect...',
        variant: SnackbarVariant.ERROR,
        testId: loginDialogIds.snackbarID.errorID,
      });
      console.error(dataError?.error);
    }
  };

  const onLoginSucceeded = () => {
    addSnackbar({
      id: 'Success',
      onClose: () => closeSnackbar('Success'),
      message: 'Welcome Back!',
      testId: loginDialogIds.snackbarID.successID,
      variant: SnackbarVariant.SUCCESS,
    });
    closeDialog();
  };


  const handleSignIn = async ({ email, password }) => {
    setErrorMessage('');
    setIsLoginLoading(true);
    try {
      await signIn({
        email: email?.toLowerCase(),
        password: password,
        autoSignIn: true,
        onLoginSucceeded: onLoginSucceeded,
        onLoginFailed: onLoginFailed,
      });
    } catch (e) {
      console.error('Internal server error', e);
    } finally {
      setIsLoginLoading(false);
    }
  };

  const setResponseData = (
    res: FunctionRunResponse<DataErrorAuthTypes, undefined>,
  ) => {
    if (res?.dataError) {
      if (isSocialCreateAnAccountWithoutChannel(res?.dataError)) {
        const snackbarId = 'selectChannelMessage';
        addSnackbar({
          id: snackbarId,
          onClose: () => closeSnackbar(snackbarId),
          message:
            'Firstly, you must type in your zip code, then create account',
          variant: SnackbarVariant.WARNING,
          testId: loginDialogIds.snackbarID.warningID,
        });
      } else {
        setErrorMessage(formatGraphQLError(res.dataError.error));
      }
    } else if (res?.functionError) {
      setErrorMessage(formatGraphQLError(res.functionError.error));
    } else if (res && res.data) {
      closeDialog();
    }
  };

  const responseGoogle = async (response) => {
    setErrorMessage('');
    if (!response?.access_token && !response?.code) {
      return;
    }

    try {
      const res = await oauthSignIn({
        accessToken: response.code || response.access_token,
        backend: AuthMethodEnum.GOOGLE,
        onLoginFailed: onLoginFailed,
        onLoginSucceeded: onLoginSucceeded,
      });
      setResponseData(res);
    } catch (err) {
      setErrorMessage(formatGraphQLError(err));
      console.error('Internal server error', err);
    }
  };
  const responseApple = async (response) => {
    setErrorMessage('');
    if (!response?.authorization?.code) {
      return;
    }

    try {
      const firstName = response?.user?.name?.firstName;
      const lastName = response?.user?.name?.lastName;
      const res = await oauthSignIn({
        accessToken: response?.authorization?.code,
        backend: AuthMethodEnum.APPLE,
        firstName,
        lastName,
        onLoginFailed,
        onLoginSucceeded,
      });
      setResponseData(res);
    } catch (err) {
      setErrorMessage(formatGraphQLError(err));
      console.error('Internal server error', err);
    }
  };
  const responseFacebook = async (response) => {
    setErrorMessage('');
    try {
      const res = await oauthSignIn({
        accessToken: response.accessToken,
        backend: AuthMethodEnum.FACEBOOK,
        onLoginFailed,
        onLoginSucceeded,
      });
      setResponseData(res);
    } catch (err) {
      console.error('Internal server error');
    }
  };

  return (
    <LoginDialog
      testIds={loginDialogIds}
      isOpen={openDialog === PageDialog.login}
      loading={isLoginLoading}
      setLoading={setLoading}
      signIn={handleSignIn}
      errorMessage={errorMessage}
      onClose={closeDialog}
      onForgotPasswordClick={openForgotPasswordDialog}
      onSignUpClick={openSignUpDialog}
      responseGoogle={responseGoogle}
      responseFacebook={responseFacebook}
      responseApple={responseApple}
    />
  );
}

import utilsData from '@cypress/fixtures/dataTestIds';
import TestObjectBase from '../TestObjectBase';
import { TestIDFormat } from '@cypress/utils/utilsNew.cy';

const { components } = utilsData.elementsDataTestIDs;

export class SnackbarIDs {
  successID: string;
  errorID: string;
  warningID: string;
  protected fullPrefix: string;

  constructor(prefix: string) {
    this.fullPrefix = `${prefix}-${components.snackbar.title}`;
    this.successID = TestIDFormat.element(
      this.fullPrefix,
      components.snackbar.success,
    );
    this.errorID = TestIDFormat.element(
      this.fullPrefix,
      components.snackbar.error,
    );
    this.warningID = TestIDFormat.element(
      this.fullPrefix,
      components.snackbar.warning,
    );
  }
}

export class Snackbar extends TestObjectBase<SnackbarIDs> {
  constructor(snackbarIDs: SnackbarIDs) {
    super(snackbarIDs);
  }

  get success() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.successID, timeout);
    };
  }

  get error() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.errorID, timeout);
    };
  }

  get warning() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.warningID, timeout);
    };
  }
}

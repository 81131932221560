import React, { useCallback, useEffect, useState } from 'react';
import { useCartContext } from '@context/CartContext';
import { useNetworkState } from '@hooks/useNetworkState';
import OrderConfirmation from './components/OrderConfirmation';
import PaymentMethodStep from './components/PaymentMethodStep';
import ShippingAddressStep from './components/ShippingAddressStep';
import SpecialRequest from './components/SpecialRequests';
import { useCheckoutPageContext } from '../../../context/CheckoutPageContext';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import AgeRestrictionStep from './components/AgeRestrictionStep';
import ErrorMessage from '@components/Atoms/ErrorMessage';
import CheckoutInfo from './components/CheckoutInfo';
import Tip from './components/Tip';
import { CompleteCheckoutResults, OrderResult } from '../../../app/checkout/types';
import DeliveryInCheckout from './components/Delivery/DeliveryInCheckout';
import { useDeliveryContext } from '@context/DeliveryContext';
import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';
import { useCheckoutPaymentContext } from '@context/CheckoutPaymentContext';
import {
  Page,
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
  Breakdown,
  Snackbar,
} from '@teddly/teddly-ui-components';
import { DeliveryTypeEnum, useAuth } from 'teddly-sdk';
import OrderNote from './components/OrderNote';
import { useRoutesContext } from '@context/RoutesContext';
import { ids } from '../../../../cypress/pages/checkout/CheckoutPage.cy';
import { ids as pageLayoutIds} from '../../../../cypress/pages/page-layout/PageLayoutPage.cy';
import useWindowDimensions from '@hooks/useWindowDimension';
import { useHomePageContext } from '@context/HomePageContext';
import { useTranslation } from 'next-i18next';
import styles from './index.module.scss'
import classNames from 'classnames';

export const enum Step {
  AGE_RESTRICTION = '21+ Age Requirement',
  SHIPPING_ADDRESS = 'Shipping Address',
  PAYMENT_METHOD = 'Payment Method',
  TIP = 'Tip the driver',
  DELIVERY_TIME = 'Delivery Time',
  PICKUP_TIME = 'Pickup time',
}

export default function CheckoutPage(): JSX.Element {
  const {
    createPayment,
    updateCheckout,
    completeCheckout,
    createCheckout,
    checkout,
    getCheckout,
  } = useCheckoutFlowContext();
  const { user } = useAuth();
  const { navigateTo, pages } = useRoutesContext();
  const {
    emptyCart,
    defaultCheckoutCount,
    total,
    subTotalPrice,
    updateLinesInLocal,
  } = useCartContext();
  const { isLoading, setIsLoading, isAgeValid, errorMessage, setErrorMessage } =
    useCheckoutPageContext();
  const { isTimeslotValid, timeslotForServerRequest } = useDeliveryContext();
  const [isError, setIsError] = React.useState<boolean>(false);
  const {
    isCheckoutShippingAddressValid,
    isShippingLoading: IsShippingLoading,
  } = useCheckoutShippingAddressContext();
  const { bodyFooterInformationProps } = useHomePageContext();
  const { isPaymentValid, isPaymentLoading, selectedCardInfo } =
    useCheckoutPaymentContext();
  const [IsSpecialRequestLoading, setIsSpecialRequestLoading] =
    useState<boolean>(false);
  const { state, setCompleted, setError } = useNetworkState<OrderResult>();
  const { t } = useTranslation('common', { keyPrefix: 'checkout' });

  useEffect(() => {
    if (state.status === 'error' || errorMessage != '') {
      setIsError(true);
    }
  }, [state.status]);

  const { isLargeTablet } = useWindowDimensions();


  useEffect(() => {
    if (!checkout?.id || checkout?.lines.length < 1) {
      navigateTo({ route: pages.HOME });
      return;
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setErrorMessage('');

    updateCheckout({
      checkoutId: checkout?.id,
      note: checkout?.note,
      itemSubstitution: checkout?.itemSubstitution,
      toivelingItems: checkout?.toivelingItems,
    }).then(async (res) => {
      setIsLoading(false);
    });
  }, [checkout?.id]);

  const handledData = (data, dataError, functionError) => {
    if (dataError || functionError) {
      setError(
        dataError?.error ||
          functionError?.error ||
          'Something went wrong creating payment with available source!',
      );
      return null;
    }
    return data;
  };

  const runCreatePayment = async (): Promise<boolean> => {
    const gatewayId = checkout?.availablePaymentGateways
      ? checkout?.availablePaymentGateways[0]?.id
      : null;
    if (!gatewayId) {
      setError('there is no payment available....');
      setIsLoading(false);
      return null;
    }
    const { data, dataError, functionError } = await createPayment({
      gateway: gatewayId,
      returnUrl: `${window.location.origin}/checkout/payment-confirm`,
    });
    return handledData(data, dataError, functionError);
  };

  const runCompleteCheckout = async () => {
    if (!selectedCardInfo) {
      setError('Please set a payment methods');
      setIsLoading(false);
      return null;
    }
    const cardId = selectedCardInfo?.creditCardInfo?.cardId
    const options = {
      paymentData: {
        cardId: cardId
        ? cardId.startsWith('{')
          ? (function () {
              const cleaned = cardId.slice(1, -1).replace(/'/g, '').trim();
              const parts = cleaned.split(',').map((part) => part.trim());
              return parts[0] || null; 
            })()
          : cardId 
        : null
   
      }      
    };
    const { data, dataError, functionError } = await completeCheckout({
      ...options,
      redirectUrl: `${window.location.origin}/order-history`,
      timeslotDatetime: timeslotForServerRequest,
    });
    return handledData(data, dataError, functionError);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      if (await runCreatePayment()) {
        const orderDataComplete: CompleteCheckoutResults = await runCompleteCheckout();
        if (orderDataComplete) {
          setCompleted(
            orderDataComplete?.order
          );
          await emptyCart();
          const { data, dataError } = await createCheckout(user.email);
          //update return data when teddly NOW return products after part of order completed
          // runUpdateCheckoutInLocalCart(data?.lines);
          updateLinesInLocal(data?.lines);

          if (dataError) throw dataError;
        }
        setIsLoading(false);
      }
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };
  const isShowLoadingProgress =
    isLoading ||
    isPaymentLoading ||
    IsShippingLoading ||
    IsSpecialRequestLoading;

  const isValidToPay = () => {
    return (
      !isLoading &&
      isCheckoutShippingAddressValid &&
      isPaymentValid &&
      isTimeslotValid &&
      isAgeValid &&
      !isShowLoadingProgress
    );
  };

  const getErrorMessageForUser = (errorMessage: string): string => {
    return errorMessage?.includes("Couldn't resolve to a node") ||
      errorMessage?.includes(
        'You need to set billing address before creating payment',
      )
      ? 'Oops, we ran into a problem. Please try to refresh the page or log out and log in'
      : errorMessage;
  };

  const content = (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {checkout?.deliveryType == DeliveryTypeEnum.SHIPPING && (
          <ShippingAddressStep
            shippingAddressDataTetsId={ids.shippingAddress}
            shippingAddressChangeButtonDataTetsId={
              ids.shippingAddressChangeButton
            }
            title={Step.SHIPPING_ADDRESS}
          />
        )}
        <DeliveryInCheckout
          title={
            checkout?.deliveryType == DeliveryTypeEnum.SHIPPING
              ? Step.DELIVERY_TIME
              : Step.PICKUP_TIME
          }
        />

        <PaymentMethodStep
          paymentMethodSectionDataTestId={ids.paymentMethodSection}
          paymentMethodChangeButtonDataTestId={ids.paymentMethodChangeButton}
          title={Step.PAYMENT_METHOD}
        />

        {/* TODO mayby */}
        <AgeRestrictionStep title={Step.AGE_RESTRICTION} />

        <SpecialRequest
          specialRequestSectionDataTestId={ids.specialRequestSection}
          itemSubstitutionDataTestId={ids.itemSubstitution}
          toivelingDataTestId={ids.toiveling}
          setIsLoading={setIsSpecialRequestLoading}
        />
        {checkout?.deliveryType == DeliveryTypeEnum.SHIPPING && (
          <Tip
            tipButtonDataTestId={ids.tipButton}
            otherTipButtonDataTestId={ids.otherTipButton}
            otherTipInputDataTestId={ids.otherTipInput}
            otherTipSaveButtonDataTestId={ids.otherTipSaveButton}
            title={Step.TIP}
          />
        )}
      </div>
      <Button
        data-testid={ids.payButton}
        title={'Pay'}
        isStickyOnMobile={isLargeTablet}
        className={classNames(styles.payButton, isLargeTablet && styles.isMobile)}
        loading={isShowLoadingProgress}
        disabled={!isValidToPay()}
        onClick={handleConfirm}
        variant={ButtonVariantEnum.FILL}
        size={ButtonSizeEnum.XXL}
        stretch
      />
      {/*  DONE UNTIL HERE FOR NOW   */}
    </>
  );

  return (
    <>
      <Page.Body
        {...bodyFooterInformationProps}
        isWithBottomNav={false}
        isWithFooterButton={false}
        className={styles.checkoutPageBody}
        sideClassName={styles.breakdown}
        title={
          <div
            data-testid={ids.title}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems:"center", lineHeight:"normal" }}>
            {t('title')}
            <OrderNote noteDataTestId={ids.noteButton} />
          </div>
        }>
        <Breakdown title="Breakdown" content={<CheckoutInfo />} />
        {content}
      </Page.Body>
      {state.status === 'completed' && (
        <OrderConfirmation
          orderResult={state?.result}
          isOpen={state.status === 'completed'}
        />
      )}
      <Snackbar
        // isOpen={Boolean(state.status === 'error' || errorMessage != '')}
        dataTestid={pageLayoutIds.snackbar}
        isOpen={isError}
        onClose={() => setIsError(false)}
        message={
          state.status === 'error'
            ? getErrorMessageForUser(state.error)
            : getErrorMessageForUser(errorMessage)
        }
        variant="error"
      />
      {/* <ErrorMessage show={state.status === 'error'} title={getErrorMessageForUser(state.error)} />
      <ErrorMessage show={errorMessage != ''} title={getErrorMessageForUser(errorMessage)} /> */}
    </>
  );
}

import { useEffect, useState } from 'react';
import styles from './Delivery.module.scss';
import DeliveryInCheckout from './DeliveryInCheckout';
import { useDeliveryContext } from '@context/DeliveryContext';
import { getNowTimeTitle, getShortSelectedDateDescription } from './utils';
import ShippingAddressStep from '../ShippingAddressStep';
import { Step } from '../..';
import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';
import {
  CheckoutTypeEnum,
  DeliveryTypeEnum,
  useAuth,
  useCheckoutOrderDeliveryUpdateMutation,
} from 'teddly-sdk';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { CurrentOpenDialog } from '../../../../../context/CheckoutPageContext';
import {
  Package as PackageIcon,
  Button,
  ButtonVariantEnum,
  ButtonSizeEnum,
  Dialog,
} from '@teddly/teddly-ui-components';
import { ids as checkoutIds } from '../../../../../../cypress/pages/checkout/CheckoutPage.cy';
import useWindowDimensions from '@hooks/useWindowDimension';
import AddHomeIcon from 'public/assets/add_home-icon.svg';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { useCartContext } from '@context/CartContext';
import { useRoutesContext } from '@context/RoutesContext';
import { FastDeliveryIcon } from '@components/Atoms/Icons/FastDeliveryIcon';
import { HeaderIDs } from '@cypress/components/navigation/Header.cy';

interface DeliveryInHeaderProps {
  show: boolean;
  showPackageIcon?: boolean;
  headerTestIds?: HeaderIDs;
}

export default function DeliveryInHeader({
  show,
  showPackageIcon = true,
  headerTestIds,
}: DeliveryInHeaderProps) {
  const testIds = headerTestIds ?? new HeaderIDs;
  const [checkoutOrderDeliveryUpdate, checkoutOrderDeliveryUpdateOpt] =
    useCheckoutOrderDeliveryUpdateMutation();
  const { pages, navigateTo } = useRoutesContext();
  const [isChange, setIsChange] = useState<boolean>();
  const { selectedTimeslot, isTimeslotNeeded, isTimeslotValid } =
    useDeliveryContext();
  const { checkout, loaded } = useCheckoutFlowContext();
  const { changeCheckoutType } = useCartContext();

  const {
    isCheckoutShippingAddressValid,
    selectedShippingAddress,
    currentOpenDialog,
    closeCurrentDialog,
    selectedShippingMethod,
    isQuickShippingUpdating,
    setIsQuickShippingUpdating,
  } = useCheckoutShippingAddressContext();
  const { user } = useAuth();
  const { openLoginDialog, setLoading, addSnackbar, closeSnackbar, dispatch } =
    usePageLayoutContext();

  const showSelectTimeslotButton =
    !user || !isCheckoutShippingAddressValid || !isTimeslotValid;
  const getDescription = () => {
    if (!isTimeslotNeeded)
      return `${selectedShippingMethod?.name}: As soon as possible`;
    if (
      !isTimeslotValid ||
      !isCheckoutShippingAddressValid ||
      !selectedTimeslot
    )
      return `${selectedShippingMethod?.name}: Select timeslot`;
    if (selectedShippingMethod?.isFastDelivery) {
      return (
        <div className={styles.newTitle}>
          <FastDeliveryIcon />
          <div>{getNowTimeTitle(selectedTimeslot)}</div>
        </div>
      );
    }
    return (
      <span>
        {checkout?.deliveryType == DeliveryTypeEnum.SHIPPING ? (
          <>
            <span>{selectedShippingAddress?.tag?.name}&nbsp;</span>
            <span style={{ color: 'rgba(204, 204, 204, 1)' }}>
              {`(${selectedShippingAddress?.postalCode})`}&nbsp;
            </span>{' '}
          </>
        ) : (
          <span>Pick up time: &nbsp;</span>
        )}
        <span>{`${getShortSelectedDateDescription(selectedTimeslot)}`}</span>
      </span>
    );
    // return `${selectedShippingMethod?.name}: ${getShortSelectedDateDescription(
    //   selectedTimeslot
    // )}`;
  };
  const executeCloseDialog = () => {
    closeCurrentDialog();
    setIsChange(false);
  };
  const onCloseHandler = async () => {
    executeCloseDialog();
    if (isQuickShippingUpdating) {
      setIsQuickShippingUpdating(false);
      if (checkout.type == CheckoutTypeEnum.EDIT_ORDER) {
        setLoading(true);
        await changeCheckoutType(CheckoutTypeEnum.DEFAULT);
        setLoading(false);
      }
    }
  };
  const navigateToCheckout = async () => {
    setLoading(true);
    executeCloseDialog();
    setIsQuickShippingUpdating(false);
    await navigateTo({ route: pages.CHECKOUT });
    setLoading(false);
  };
  const goToCart = () => {
    executeCloseDialog();
    dispatch({ type: 'TOGGLE_CART', value: true });
  };
  const getSelectedAddressIcon = () => {
    const icon = selectedShippingAddress?.tag?.icon;
    if (icon) {
      return <img src={icon?.url} alt={icon?.alt} />;
    }
    return <PackageIcon />;
  };

  const { isLargeTablet } = useWindowDimensions();

  const onSaveChangesClick = async () => {
    if (
      checkout.isQuickShippingUpdateAvailable &&
      selectedTimeslot &&
      isQuickShippingUpdating
    ) {
      const response = await checkoutOrderDeliveryUpdate({
        variables: {
          checkoutId: checkout.id,
          input: {
            timeslotDatetime: selectedTimeslot.date,
          },
        },
      });
      if (
        response.data.checkoutOrderDeliveryUpdate.errors.length > 0 ||
        !response.data.checkoutOrderDeliveryUpdate.checkout
      ) {
        addSnackbar({
          id: 'error',
          onClose: () => closeSnackbar('error'),
          message:
            'Quick edit of shipping details was not possible. Continue to checkout and update your order.',
          variant: SnackbarVariant.ERROR,
        });
        navigateToCheckout();
      } else {
        if (isQuickShippingUpdating) {
          addSnackbar({
            id: 'SUCCESS',
            onClose: () => closeSnackbar('SUCCESS'),
            message: 'Shipping details have been updated.',
            variant: SnackbarVariant.SUCCESS,
          });
          await navigateTo({ route: pages.ORDER_HISTORY });
          onCloseHandler();
        } else {
          navigateToCheckout();
        }
      }
    } else if (
      (!isQuickShippingUpdating && checkout.isQuickShippingUpdateAvailable) ||
      !selectedTimeslot
    ) {
      goToCart();
    } else {
      navigateToCheckout();
    }
  };

  const isOpen =
    isChange ||
    currentOpenDialog === CurrentOpenDialog.PickAddress ||
    currentOpenDialog === CurrentOpenDialog.SelectDelivery;

  useEffect(() => {
    if (
      isOpen &&
      currentOpenDialog === CurrentOpenDialog.SelectDelivery &&
      !isChange
    ) {
      setIsChange(true);
    }
  }, [currentOpenDialog, isOpen, isChange]);

  return (
    show && (
      <span className={styles.deliveryInHeader}>
        {showSelectTimeslotButton ? (
          <Button
            variant={ButtonVariantEnum.NEGATIVE}
            size={ButtonSizeEnum.MEDIUM}
            data-testid={testIds.pickDeliverySlotBtnID}
            icon={<AddHomeIcon />}
            title={
              checkout?.deliveryType == DeliveryTypeEnum.PICK_UP
                ? 'Select pickup time'
                : 'Pick delivery slot'
            }
            onClick={() => {
              if (!user) {
                return openLoginDialog();
              }
              // setIsDialogOpen(true);
              setIsChange(true);
            }}
          />
        ) : (
          <Button
            variant={ButtonVariantEnum.NEGATIVE}
            titleClassName={styles.selectDeliveryTimeButton}
            data-testid={testIds.pickDeliverySlotBtnID}
            size={ButtonSizeEnum.MEDIUM}
            icon={
              !selectedShippingMethod?.isFastDelivery && showPackageIcon
                ? getSelectedAddressIcon()
                : null
            }
            title={getDescription()}
            onClick={() => {
              // setIsDialogOpen(true);
              setIsChange(true);
            }}
          />
        )}
        {
          <Dialog
            onClose={onCloseHandler}
            className={styles.deliveryInHeaderDialog}
            isOpen={isOpen}
            style={{ width: !isLargeTablet ? '740px' : null }}
            title={
              selectedShippingAddress ||
              checkout?.deliveryType == DeliveryTypeEnum.PICK_UP
                ? `Schedule ${checkout?.deliveryType == DeliveryTypeEnum.PICK_UP ? 'Pickup' : 'delivery'}`
                : 'Where to?'
            }>
            <div className={styles.dialogContainer} style={{ width: '100%' }}>
              {checkout?.deliveryType == DeliveryTypeEnum.SHIPPING && (
                <ShippingAddressStep
                  title={Step.SHIPPING_ADDRESS}
                  shippingAddressDataTetsId={checkoutIds.shippingAddress}
                  shippingAddressChangeButtonDataTetsId={
                    checkoutIds.shippingAddressChangeButton
                  }
                />
              )}
              <DeliveryInCheckout
                title={
                  checkout?.deliveryType == DeliveryTypeEnum.PICK_UP
                    ? 'Pickup time'
                    : 'Delivery time'
                }
                onCloseHeaderDialog={onCloseHandler}
              />
              {!!checkout?.editingOrder && (
                <Button
                  title={'save changes'}
                  size={ButtonSizeEnum.XXL}
                  stretch
                  fullWidth
                  className={styles.saveChangesButton}
                  disabled={
                    !isTimeslotValid ||
                    !loaded ||
                    checkoutOrderDeliveryUpdateOpt.loading
                  }
                  loading={checkoutOrderDeliveryUpdateOpt.loading}
                  onClick={onSaveChangesClick}
                />
              )}
            </div>
          </Dialog>
        }
      </span>
    )
  );
}

import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { IItem, IItemIDs } from '@cypress/interfaces/IItem.cy';
import TestObjectBase from '../TestObjectBase';
import {
  ItemUnitController,
  ItemUnitControllerIDs,
} from './ItemUnitController.cy';

const { namedElements: elm } = utilsData.elementsDataTestIDs;

export class ItemBaseIDs implements IItemIDs {
  imageID: string;
  nameID: string;
  priceID: string;
  priceUnitID: string;
  unitSizeID: string;
  unitControllerID: ItemUnitControllerIDs;

  protected fullPrefix: string;

  constructor(prefix: string) {
    this.fullPrefix = prefix;
    this.imageID = TestIDFormat.element(this.fullPrefix, elm.image);
    this.nameID = TestIDFormat.element(this.fullPrefix, elm.name);
    this.priceID = TestIDFormat.element(this.fullPrefix, elm.price);
    this.priceUnitID = TestIDFormat.element(this.fullPrefix, elm.priceUnit);
    this.unitSizeID = TestIDFormat.element(this.fullPrefix, elm.unitSize);
    this.unitControllerID = new ItemUnitControllerIDs(this.fullPrefix);
  }
}

export class ItemBase<T extends ItemBaseIDs>
  extends TestObjectBase<T>
  implements IItem
{
  constructor(itemBaseIDs: ItemBaseIDs) {
    super(itemBaseIDs);
  }

  get image() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.imageID, timeout);
    };
  }
  get name() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.nameID, timeout);
    };
  }
  get price() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.priceID, timeout);
    };
  }

  get priceUnit() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.priceUnitID, timeout);
    };
  }
  get unitSize() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.unitSizeID, timeout);
    };
  }

  get unitController() {
    return new ItemUnitController(this.testIDs.unitControllerID);
  }
}

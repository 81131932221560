import utilsData from '../../fixtures/dataTestIds';
import TestObjectBase from '../TestObjectBase';
import { SnackbarIDs, Snackbar } from '../other-components/Snackbar.cy';

const { components } = utilsData.elementsDataTestIDs;

export class PageIDs {
  snackbarID: SnackbarIDs;

  protected fullPrefix: string;

  constructor(prefix: string) {
    this.fullPrefix = `${prefix}-${components.pages.title}`;
    this.snackbarID = new SnackbarIDs(this.fullPrefix);
  }
}

export class Page<T extends PageIDs> extends TestObjectBase<T> {
  constructor(PageIDs) {
    super(new PageIDs());
  }

  get snackbar() {
    return new Snackbar(this.testIDs.snackbarID);
  }
}

import utilsData from '@cypress/fixtures/dataTestIds';
import { ItemBase, ItemBaseIDs } from '../other-components/ItemBase.cy';
import { Collapsible, CollapsibleIDs } from './Collapsible.cy';
import { TestIDFormat } from '@cypress/utils/utilsNew.cy';

const { components, namedElements: elm } = utilsData.elementsDataTestIDs;

export class ItemCollapsibleIDs extends CollapsibleIDs {
  logoID: string;
  itemID: ItemBaseIDs;

  constructor(prefix: string) {
    super(`${prefix}-${components.collapsible.item}`);
    this.logoID = TestIDFormat.element(this.fullPrefix, elm.logo);
    this.itemID = new ItemBaseIDs(this.fullPrefix);
  }
}

export class ItemCollapsible extends Collapsible<ItemCollapsibleIDs> {
  constructor(itemCollapsibleIDs: ItemCollapsibleIDs) {
    super(itemCollapsibleIDs);
  }

  get logo() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.logoID, timeout);
    };
  }
  get item() {
    return new ItemBase(this.testIDs.itemID);
  }
}

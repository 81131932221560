import '@styles/index.scss';
import 'node_modules/@teddly/teddly-ui-components/dist/index.css';
import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../../next-i18next.config';
import AppProviders from '@context/AppProviders';
import { AppProps } from 'next/dist/shared/lib/router/router';
import { GoogleTagManager } from '@next/third-parties/google';
import PageLayout from '@components/Content/PageLayout';
import { SplashScreen } from '@teddly/teddly-ui-components';
import { initialPendoAnalytics } from '@config/pendo/pendoInit';

import { ids } from '@cypress/pages/page-layout/PageLayoutPage.cy';
import AppContent from './appContent';

// import { useAuth } from 'teddly-sdk';

// if (
//   typeof window !== 'undefined' &&
//   (process.env.NODE_ENV === 'development' || window.Cypress)
// ) {

// const { worker } = require('../mocks/browser');
// worker.start();

// }

function App(props: AppProps) {
  // const { optOutFromLayout, ...otherProps } = pageProps;

  const updatePrimaryColorByUrl = (): void => {
    const host = window.location.host;
    let primaryColor = null;
    let primaryColorHover = null;
    if (host?.includes('teddly.dev')) {
      primaryColor = '30,144,255';
      primaryColorHover = '0,124,248';
    }
    if (host?.includes('moishas.com')) {
      primaryColor = '199, 38, 38';
      primaryColorHover = '199, 38, 38';
    }
    if (primaryColor) {
      document.documentElement.style.setProperty('--primary', primaryColor);
    }
    if (primaryColorHover) {
      document.documentElement.style.setProperty(
        '--primaryhover',
        primaryColorHover,
      );
    }
  };

  useEffect(() => {
    updatePrimaryColorByUrl();
  }, []);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/firebase-messaging-sw.js').then(
          function (registration) {
            console.log(
              'Service Worker registration successful with scope: ',
              registration.scope,
            );
          },
          function (err) {
            console.log('Service Worker registration failed: ', err);
          },
        );
      });
    }

    window.addEventListener('message', async function (event) {
      // Ensure that the message is coming from a trusted source
      // console.log(event.origin)
      // console.log(event.data)
      if (event.origin === process.env.NEXT_PUBLIC_DASHBOARD_URL) {
        const { token, csrf_token } = event.data;
        if (token && csrf_token) {
          localStorage.setItem('token', token);
          localStorage.setItem('csrf_token', csrf_token);
          window.location.reload();
        }
      }
    });
  }, []);

  useEffect(() => {
    const pendoApiKey = '1ec27af1-b5ab-4ac6-7b72-4cee6c73da74'; //Move to .env file?
    initialPendoAnalytics(pendoApiKey);
  }, []);

  return (
    <>
      <AppProviders>
        <AppContent {...props} />
      </AppProviders>
    </>
  );
}

export default appWithTranslation(App, nextI18NextConfig);

import {
  EMAIL_MAX_LENGTH,
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from '@config/validation';

const messages = {
  texts: {
    heading: 'Welcome To Teddly',
  },
  success: 'An Email has been sent! Please verify your Account',
  error: '',

  validations: {
    email: 'Please enter valid Email',
    firstName: 'First Name is required',
    lastName: 'Last Name is required',
    phone: 'Please enter valid Phone Number',
    password: 'Password is required',
  },

  lengthValidations: {
    email: `email is more then ${EMAIL_MAX_LENGTH} Characters`,
    firstName: `First name is more than ${FIRST_NAME_MAX_LENGTH} Characters`,
    lastName: `Last name is more then ${LAST_NAME_MAX_LENGTH} Characters`,
    password: `password should be between ${PASSWORD_MIN_LENGTH} and ${PASSWORD_MAX_LENGTH} Characters`,
  },
  resetPasswordValidation: {
    currentPassword: 'Current Password is required!',
    newPassword: 'New password is required',
    confrimPassword: 'Confirm Password is required!',
    confrimDontMatchPassword: 'The passwords do not match',
  },
};

export default messages;

import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import TestObjectBase from '../TestObjectBase';

const { inputFields: input, components } = utilsData.elementsDataTestIDs;

export class AddToShoppingListDropdownIDs {
  searchInputID: string;
  listNameID: string;
  addBtnID: string;
  valueID: string;
  subtractBtnID: string;
  protected fullPrefix: string;

  constructor(prefix: string) {
    this.fullPrefix = `${prefix}-${components.dropdown.addToShoppingList.title}-${components.dropdown.title}`;
    this.searchInputID = TestIDFormat.inputField(this.fullPrefix, input.search);
    this.listNameID = TestIDFormat.element(
      this.fullPrefix,
      components.dropdown.addToShoppingList.listName,
    );
    this.valueID = TestIDFormat.element(
      this.fullPrefix,
      components.unitController.value,
    );
    this.addBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.unitController.add,
    );
    this.subtractBtnID = TestIDFormat.button(
      this.fullPrefix,
      components.unitController.subtract,
    );
  }
}

export class AddToShoppingListDropdown extends TestObjectBase<AddToShoppingListDropdownIDs> {
  constructor(addToShoppingListDropdownIDs: AddToShoppingListDropdownIDs) {
    super(addToShoppingListDropdownIDs);
  }

  get searchInput() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.searchInputID, timeout);
    };
  }

  get value() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.valueID, timeout);
    };
  }

  get add() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.addBtnID, timeout);
    };
  }

  get listName() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.listNameID, timeout);
    };
  }

  get subtract() {
    return (timeout?: number) => {
      return this.getElement(this.testIDs.subtractBtnID, timeout);
    };
  }
}
